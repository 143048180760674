import React, { useEffect, useState, useRef } from "react";
import logoimg from "../../assets/images/Web Logo.png";
import checkicon from "../../webflowimages/checkicon.svg";
import loginservice from "../../store/services/loginservice";
import { useSnackbar } from "../common/snackbar";
import { styled } from "@mui/material/styles";
import { Dialog } from "primereact/dialog";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import {
  Box,
  Button,
  Card,
  // Dialog,
  // DialogActions,
  // DialogContent,
  // DialogTitle,
  Icon,
  MenuItem,
  Modal,
  IconButton as MuiIconButton,
  Tab,
  TextField,
} from "@mui/material";
import { fetchRelationDetails } from "../../store/actions/myprofile";
import { useSnackbarerror } from "../common/snackbarerror";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { APP_ROUTES } from "../../constants/approutes";
import moment from "moment";

const AppointmentNew = () => {
  const divRef = useRef(null);
  const [err1, setErr1] = useState("");
  const [careReceivers, setCareReceivers] = useState([{ id: 1, formData: {} }]);
  const [loading, setLoading] = useState(false);
  const { snackbarMessage, setSnackbarMessage } = useSnackbar();
  const [showresponsedata, setShowresponsedata] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [open, setOpen] = useState(false);
  const [isActive, setIsActive] = useState([true]);
  const [careReceiverLimitError, setCareReceiverLimitError] = useState("");
  const [relations, setRelations] = useState([]);
  const { snackbarerrorMessage, setSnackbarerrorMessage } = useSnackbarerror();
  const [isConsentChecked, setIsConsentChecked] = useState(false);
  const [value, setValue] = useState("1");
  const [consent, setConsent] = useState();
  const [bytecode, setBytecode] = useState();
  const [isloading, setIsLoading] = useState(false);
  const [subscriptionConsent, setSubscriptionConsent] = useState(<></>)
  const todayEffectiveDate = new Date();
  const options = { day: "numeric", month: "long", year: "numeric" };
  const formattedDate = todayEffectiveDate.toLocaleDateString(
    undefined,
    options
  );
  const IconButton = styled(MuiIconButton)({
    backgroundColor: "white",
    borderColor: "#004171 !important",
    borderWidth: "1px !important",
    border: "solid",
    color: "#004171",
    "&:hover": {
      backgroundColor: "#004171",
      color: "white",
    },
  });
  const toggleIsActive = (index) => {
    setIsActive((prevIsActive) => {
      const updatedIsActive = Array(careReceivers.length).fill(false);
      updatedIsActive[index] = true;
      return updatedIsActive;
    });
  };

  const addCareReceiver = () => {
    if (careReceivers.length < 3) {
      setCareReceivers((prevCareReceivers) => [
        ...prevCareReceivers,
        { id: prevCareReceivers.length + 1, formData: {} },
      ]);
    } else {
      setCareReceiverLimitError("Maximum of 3 care receivers can be added");
      setTimeout(() => {
        setCareReceiverLimitError("");
      }, 5000);
    }
  };

  const [formData, setFormData] = useState({
    name: "",
    number: "",
    email: "",
    patientName: "",
    patientPhoneNo: "",
    relation: "",
    location: "",
    pinCode: "",
  });
  const handleChange = (event, careReceiverIndex = null) => {
    const { name, value } = event.target;

    if (careReceiverIndex !== null) {
      const updatedCareReceivers = [...careReceivers];
      updatedCareReceivers[careReceiverIndex].formData[name] = value;
      setCareReceivers(updatedCareReceivers);
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };
  const handleClose = () => {
    setOpen(false);
  };

  console.log(careReceivers[0].formData.location, "careReceivers");
  const handleDailog = () => {
    let html = ''
    const parser = new DOMParser();
                  const doc = parser.parseFromString(subscriptionConsent, "text/html");
                  doc.querySelectorAll('i').forEach(el => {
                    if (el.innerHTML.includes('Subscriber') && el.innerHTML.includes('Name')) {
                      el.innerHTML = el.innerHTML.replace(/Subscriber[\s\S]*?Name/, formData.name);
                    }
                    if(el.innerHTML == 'Auto Insert Date'){
                      el.innerHTML = moment(new Date()).format("DD/MM/YYYY")
                    }
                    if(el.innerHTML == 'insert address of the Subscriber'){
                      el.innerHTML = careReceivers[0].formData.location
                    }
                  });
                  const serializer = new XMLSerializer();
html = serializer.serializeToString(doc);
                 setSubscriptionConsent(html);
    if (formData.name == "") {
      setSnackbarMessage("Please fill up the mandatory fields", "error");
    } else if (careReceivers[0].formData.location === undefined) {
      setSnackbarMessage("Please fill up the mandatory fields", "error");
    } else {
      setOpen(true);
    }
  };

  const convertToPDFAndSendToAPI = async () => {
    const content = document.getElementById("contentContainer").innerText;
    const pdf = new jsPDF();

    let yPosition = 10;
    const margin = 10;
    const pageWidth = pdf.internal.pageSize.width - 2 * margin;

    pdf.setFontSize(12);

    const addPage = () => {
      pdf.addPage();
      yPosition = margin;
    };

    pdf.setTextColor(0, 0, 0);

    const lines = pdf.splitTextToSize(content, pageWidth);

    lines.forEach((line) => {
      if (
        yPosition + pdf.getTextDimensions(line).h >
        pdf.internal.pageSize.height - margin
      ) {
        addPage();
      }

      pdf.setTextColor(0, 0, 0);

      pdf.text(line, margin, yPosition);
      yPosition += pdf.getTextDimensions(line).h + 2;
    });

    // pdf.save('consent.pdf');
    // const byteArray = pdf.output();

    const base64String = pdf.output("dataurlstring");
    const base64WithoutPrefix = base64String.split(",")[1];

    console.log(base64WithoutPrefix, "base64");
    setBytecode(base64WithoutPrefix);
  };

  const handleAgree = () => {
    setIsConsentChecked(true);
    setOpen(false);
    convertToPDFAndSendToAPI();
  };
  const handleDisagree = () => {
    setIsConsentChecked(false);
    setOpen(false);
  };
  const handleConsentCheck = () => {
    setIsConsentChecked(!isConsentChecked);

    // convertToPDFAndSendToAPI();
  };

  const initialFormData = {
    name: "",
    number: "",
    email: "",
    patientName: "",
    patientPhoneNo: "",
    relation: "",
    location: "",
    pinCode: "",
  };

  useEffect(() =>{
    async function fetchSubscriptionConsent(){
        let apiEndpoint = `${process.env.REACT_APP_NEWBASEURL}ConsentVersion/list/filter?userType=TC_W_PC`
        fetch(apiEndpoint)
            .then(response => response.json())
            .then(data => {
              console.log(data, "sdvksdvhsdivdssdvsdvs")
              if(data.data.length === 0){
                return;
              }
                const htmlPath = data.data[0].consentFile;
                // Construct the full URL to the HTML file if needed
                const fullHtmlUrl = `${process.env.REACT_APP_IMAGE_URL}${htmlPath}`;
                // let updatedHtml = fullHtmlUrl.replace('Auto Insert Date', moment(new Date()).format("DD/MM/YYYY"))
                // setSubscriptionConsent(updatedHtml) 

                return fetch(fullHtmlUrl);
            })
            .then(response => response.text())
            .then(html => {
                 setSubscriptionConsent(html);
            })
            .catch(error => console.error('Error fetching HTML content:', error));
    }

    fetchSubscriptionConsent()
  },[])

  const handleSubmit = async () => {
    console.log(bytecode, "byteArray123");
    const phoneRegex =
      /^\+?\d{1,4}[-.\s]?\(?\d{1,4}\)?[-.\s]?\d{6,}(?=\D*\d\D*$)/;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const PincodeRegex = /^[1-9][0-9]{5}$/;
    const careReceiversData = [];
    const careReceiverErrors = {};

    if (formData?.name == "") {
      setErr1("Please enter name");
    } else if (formData?.number == "") {
      setErr1("Please enter phone number");
    } else if (!phoneRegex.test(formData?.number)) {
      setErr1("Please enter valid phone number");
    } else if (!emailRegex.test(formData?.email) || formData?.email === "") {
      setErr1("Please enter a valid email");
    } else if (
      formData?.name !== "" &&
      emailRegex.test(formData?.email) &&
      phoneRegex.test(formData?.number)
    ) {
      careReceivers.forEach((careReceiver, index) => {
        const { patientName, relation, patientPhoneNo, location, pinCode } =
          careReceiver.formData;

        if (
          patientName !== "" &&
          patientName !== undefined &&
          relation !== "" &&
          relation !== undefined &&
          patientPhoneNo !== "" &&
          patientPhoneNo !== undefined &&
          location !== "" &&
          location !== undefined &&
          pinCode !== "" &&
          pinCode !== undefined
        ) {
          if (!PincodeRegex.test(pinCode)) {
            careReceiverErrors[index] = "Please enter a valid PIN code";
          } else if (!phoneRegex.test(patientPhoneNo)) {
            careReceiverErrors[index] = "Please enter valid phone number";
          } else {
            careReceiversData.push({
              firstName: patientName,
              relation,
              mobile: patientPhoneNo,
              address: location,
              pinCode,
              alreadyBooked: "N",
            });
          }
        } else {
          careReceiverErrors[index] =
            "Please fill in all care receiver details";
        }
      });
      if (isConsentChecked == false) {
        setErr1("Please Read and Sign Consent Form");
      } else {
        const errorKeys = Object.keys(careReceiverErrors);
        if (errorKeys.length > 0) {
          errorKeys.forEach((key) => {
            setErr1(careReceiverErrors[key]);
          });
        } else {
          setErr1("");
          setLoading(true);
          setIsLoading(true);
          convertToPDFAndSendToAPI();
          const { name, number, email } = formData;
          const payload = {
            firstName: name,
            mobile: number,
            email,
            consentForm: isConsentChecked ? bytecode : bytecode,
            consentFormFlag: isConsentChecked ? "1" : "0",
            preAssessmentParentVOList: careReceiversData,
          };

          window.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
          window.dataLayer.push({
            event: "generate_lead",
            ecommerce: {
              value: 100,
              items: [
                {
                  firstName: name,
                  mobile: number,
                  email,
                  consentFormFlag: isConsentChecked ? "1" : "0",
                  preAssessmentParentVOList: careReceiversData,
                },
              ],
            },
          });

          try {
            const res = await loginservice.preAssesmentSave(payload);
            console.log(res?.data?.responseCode, res?.status, "jhjkjh");
            setLoading(false);
            handleClose();
            if (res?.status && res?.data?.responseCode === "200") {
              setSnackbarMessage(res.data?.responseMessage, "success");

              console.log("Before resetting state:", careReceivers);
              setFormData(initialFormData);
              setIsLoading(false);
              setCareReceivers([{ id: 1, formData: {} }]);
            } else if (
              res?.status === 200 &&
              res?.data?.responseCode === "500"
            ) {
              // setFormData(initialFormData);
              setIsLoading(false);
              setSnackbarMessage(res.data?.responseMessage, "error");
              // setCareReceivers([{ id: 1, formData: {} }]);
            } else {
              setSnackbarMessage(res?.data?.responseMessage, "error");
              setFormData(initialFormData);
              setIsLoading(false);
              setCareReceivers([{ id: 1, formData: {} }]);
            }
          } catch (error) {
            console.error("Failed to save", error);
          }
        }
      }
    }
  };
  const handleYes = async () => {
    setLoading(true);
    const { name, number, email } = formData;
    const careReceiversData = [];
    careReceivers.forEach((careReceiver, index) => {
      const { patientName, relation, patientPhoneNo, location, pinCode } =
        careReceiver.formData;

      careReceiversData.push({
        firstName: patientName,
        relation,
        mobile: patientPhoneNo,
        address: location,
        pinCode,
        alreadyBooked: "Y",
      });
    });

    const payload = {
      firstName: name,
      mobile: number,
      email,
      preAssessmentParentVOList: careReceiversData,
    };
    try {
      const res = await loginservice.preAssesmentSave(payload);
      setLoading(false);
      handleClose();
      if (res?.status && res?.data?.responseCode === "200") {
        setSnackbarMessage(res.data?.responseMessage, "success");
        setShowModal(false);
      } else if (res?.data?.responseCode === "500") {
        setShowresponsedata(res?.data?.responseMessage);
        setShowModal(true);
      } else {
        setSnackbarMessage(res?.data?.responseMessage, "error");
      }
      setFormData({});
    } catch (error) {
      console.error("Failed to save", error);
    }
  };

  useEffect(() => {
    async function fetchData() {
      const relationDetailsResponse = await fetchRelationDetails();
      if (
        relationDetailsResponse?.status === 200 &&
        relationDetailsResponse?.data?.succesObject
      ) {
        setRelations(relationDetailsResponse.data.succesObject);
      }
    }
    fetchData();
  }, []);
  const remove = (index) => {
    let data = [...careReceivers];
    data.splice(index, 1);
    toggleIsActive(index - 1);
    setCareReceivers(data);
  };

  const isMobile = () => window.innerWidth <= 768;
  return (
    <>
      {snackbarMessage}
      {snackbarerrorMessage}
      <div className="section hero booking">
        <div className="container-default width-100 w-container">
          <div className="w-layout-grid grid-2-columns booking-page-grid">
            <div
              data-w-id="c08f68c4-7de3-76c9-6bba-a9dba4f5fa20"
              // style={{
              //     WebkitTransform: "translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
              //     MozTransform: "translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
              //     msTransform: "translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
              //     transform: "translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
              // }}
              className="inner-container _444px _100---tablet mt-[71px] sm:mt-0"
            >
              <h1 className="display-1">
                Book a Free Health Assessment for your loved ones
              </h1>
              <div className="w-layout-grid grid-1-column gap-row-16px">
                <div class="icon-list-item-wrapper">
                  <div className="text-bold">
                    <div className="line-height-1-2em mg-top-3px">
                      Totalcare Members get:
                    </div>
                  </div>
                </div>
                <div className="icon-list-item-wrapper">
                  <img src={checkicon} alt="" className="icon-list" />
                  <div className="text-bold">
                    <div className="line-height-1-2em mg-top-3px">
                      Access to senior elite doctors
                    </div>
                  </div>
                </div>
                <div className="icon-list-item-wrapper">
                  <img src={checkicon} alt="" className="icon-list" />
                  <div className="text-bold">
                    <div className="line-height-1-2em mg-top-3px">
                      Full Body Lab Tests
                    </div>
                  </div>
                </div>
                <div className="icon-list-item-wrapper">
                  <img src={checkicon} alt="" className="icon-list" />
                  <div className="text-bold">
                    <div className="line-height-1-2em mg-top-3px">
                      Home Visits of Doctors, Nurses
                    </div>
                  </div>
                </div>

                <div className="icon-list-item-wrapper">
                  <img src={checkicon} alt="" className="icon-list" />
                  <div className="text-bold">
                    <div className="line-height-1-2em mg-top-3px">
                      Dedicated Concierge during hospitalisation
                    </div>
                  </div>
                </div>

                <div className="icon-list-item-wrapper">
                  <img src={checkicon} alt="" className="icon-list" />
                  <div className="text-bold">
                    <div className="line-height-1-2em mg-top-3px">
                      Ambulance Services
                    </div>
                  </div>
                </div>

                <div className="icon-list-item-wrapper">
                  <img src={checkicon} alt="" className="icon-list" />
                  <div className="text-bold">
                    <div className="line-height-1-2em mg-top-3px">
                      On Demand – Home Services (X-Ray, ECG etc.)
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              id="w-node-c08f68c4-7de3-76c9-6bba-a9dba4f5fa3b-4d0e8dc1"
              data-w-id="c08f68c4-7de3-76c9-6bba-a9dba4f5fa3b"
              className="card contact-form-card booking-form w-form sm:mt-40 mt-0"
            >
              <div>
                <h3
                  id="w-node-e82aa137-1cbb-2de1-aba7-a0b8c0b60c1f-4d0e8dc1"
                  className="heading-2 mb-5"
                >
                  Care Sponsor Details
                </h3>
                <div className="w-layout-grid grid-2-columns form">
                  <div>
                    <label htmlFor="Name">
                      Full name<spam style={{ color: "#F34040" }}>*</spam>
                    </label>
                    <div className="position-relative">
                      <input
                        type="text"
                        className="input w-input"
                        maxLength={256}
                        required
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        placeholder="Enter Name"
                      />

                      <div className="floating-item icon" />
                    </div>
                  </div>
                  <div>
                    <label htmlFor="Name-2">
                      Phone number<spam style={{ color: "#F34040" }}>*</spam>
                    </label>
                    <div className="position-relative">
                      <input
                        required
                        minLength="8"
                        maxLength="13"
                        type="tel"
                        name="number"
                        value={formData.number}
                        onChange={handleChange}
                        className="input w-input"
                        placeholder="Enter Phone Number"
                      />
                      <div className="floating-item icon" />
                    </div>
                  </div>
                  <div>
                    <label htmlFor="Phone">
                      Email ID<spam style={{ color: "#F34040" }}>*</spam>
                    </label>
                    <div className="position-relative">
                      <input
                        required
                        maxLength={256}
                        type="text"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        className="input w-input"
                        placeholder="Enter Email ID"
                      />
                      <div className="floating-item icon" />
                    </div>
                  </div>
                </div>

                <div className="div-block-18">
                  <h3 className="heading-2 booking">Care Recipient Details</h3>
                  {/* <a href="/booking" aria-current="page" className="btn-secondary small w-button w--current">
                                Add Care Receiver</a> */}
                  <button
                    id="addCareReceiver-button"
                    className="btn-secondary small w-button w--current"
                    size="medium"
                    fullWidth
                    onClick={addCareReceiver}
                  >
                    Add Care Recipient
                  </button>
                </div>
                {careReceivers.map((careReceiver, index) => (
                  <div key={index}>
                    <div className="my-4 flex items-center justify-between">
                      <h2
                        className="sm:text-xl text-lg"
                        style={{
                          fontFamily: "Open Sans",
                          fontStyle: "normal",
                          fontWeight: "600",
                          color: "#1A1A1A",
                        }}
                      >
                        Recipient Details {index + 1}:
                      </h2>
                      <div className="flex items-center">
                        <IconButton
                          size="small"
                          sx={{ backgroundColor: "#E4FAFF" }}
                          onClick={() => toggleIsActive(index)}
                        >
                          {isActive[index] ? (
                            <Icon fontSize="inherit" color="#262626">
                              expand_less
                            </Icon>
                          ) : (
                            <Icon fontSize="inherit" color="#262626">
                              expand_more
                            </Icon>
                          )}
                        </IconButton>
                      </div>
                    </div>
                    {isActive[index] && (
                      <div className="w-layout-grid grid-2-columns form">
                        <div>
                          <label htmlFor="Name-3">
                            Full name<spam style={{ color: "#F34040" }}>*</spam>
                          </label>
                          <div className="position-relative">
                            <input
                              type="text"
                              className="input w-input"
                              maxLength={256}
                              name="patientName"
                              data-name="Name 3"
                              id="Name-3"
                              required
                              placeholder="Enter Name"
                              value={careReceiver.formData.patientName || ""}
                              onChange={(event) => handleChange(event, index)}
                            />

                            <div className="floating-item icon" />
                          </div>
                        </div>
                        <div>
                          <label htmlFor="Name-3">
                            Phone Number
                            <spam style={{ color: "#F34040" }}>*</spam>
                          </label>
                          <div className="position-relative">
                            <input
                              required
                              minLength="8"
                              maxLength="13"
                              name="patientPhoneNo"
                              value={careReceiver.formData.patientPhoneNo || ""}
                              onChange={(event) => handleChange(event, index)}
                              className="input w-input"
                              placeholder="Enter Phone Number"
                            />
                            <div className="floating-item icon" />
                          </div>
                        </div>
                        <div>
                          <label htmlFor="Phone">
                            Select Relationship
                            <spam style={{ color: "#F34040" }}>*</spam>
                          </label>
                          <div className="position-relative">
                            {/* <input
                                        type="text"
                                        className="input w-input"
                                        maxLength={256}
                                        name="Location"
                                        data-name="Location"
                                        id="Location"
                                        required="" /> */}
                            <div>
                              <select
                                select
                                required
                                name="relation"
                                value={careReceiver.formData.relation || ""}
                                onChange={(event) => handleChange(event, index)}
                                className="input w-input"
                              >
                                <option value="">Select Relationship</option>
                                {relations &&
                                  relations?.map((value, index) => (
                                    <option
                                      value={value.relationship}
                                      key={index}
                                    >
                                      <p className="text-base font-semibold">
                                        {value.relationship}
                                      </p>
                                    </option>
                                  ))}
                              </select>
                            </div>
                            <div className="floating-item icon" />
                          </div>
                        </div>
                        <div>
                          <label htmlFor="Phone">
                            Pincode<spam style={{ color: "#F34040" }}>*</spam>
                          </label>
                          <div className="position-relative">
                            <input
                              required
                              maxLength="8"
                              name="pinCode"
                              placeholder="Enter Pincode"
                              value={careReceiver.formData.pinCode || ""}
                              onChange={(event) => handleChange(event, index)}
                              className="input w-input"
                            />
                            <div className="floating-item icon" />
                          </div>
                        </div>
                        <div>
                          <label htmlFor="Phone">
                            Location<spam style={{ color: "#F34040" }}>*</spam>
                          </label>
                          <div className="position-relative">
                            <input
                              required
                              name="location"
                              placeholder="Enter Location"
                              value={careReceiver.formData.location || ""}
                              onChange={(event) => handleChange(event, index)}
                              className="input w-input"
                            />
                            <div className="floating-item icon" />
                          </div>
                        </div>
                        <div>
                          {index !== 0 && (
                            <div className="flex items-end ml-auto">
                              <button
                                id="remove-button"
                                size="small"
                                className="btn-secondary small w-button w--current"
                                onClick={() => remove(index)}
                              >
                                Remove
                              </button>
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                ))}
                <div className="flex justify-start items-start pb-6 sm:pt-5 pt-0">
                  {isConsentChecked == true ? (
                    <input
                      className=" mr-3 mt-[3px]"
                      checked
                      type="checkbox"
                      // checked={isConsentChecked}
                      // onChange={handleConsentCheck}
                    />
                  ) : (
                    ""
                  )}
                  <span className="text-sm"> Read and Sign </span>
                  <span
                    onClick={handleDailog}
                    className="text-seegreen text-sm  font-bold underline cursor-pointer pl-1"
                  >
                    {" "}
                    Consent Form{" "}
                  </span>{" "}
                  {/* {consent?.attributes?.SecondComp?.name} */}
                </div>
                <div className="text-center mt-6">
                  <span className="text-red-600 font-medium text-base pb-4 text-center tracking-widest">
                    {err1}
                  </span>
                </div>
                <div
                  id="w-node-fcd9f6af-3df2-8075-69c9-d85998b45416-4d0e8dc1"
                  data-w-id="fcd9f6af-3df2-8075-69c9-d85998b45416"
                  className="mg-top-8px mg-top-4px-mbp"
                >
                  {isloading == true ? (
                    <input
                      type="text"
                      defaultValue="Loading.."
                      className="btn-primary width-100 w-button"
                      style={{
                        cursor: "not-allowed",
                      }}
                    />
                  ) : (
                    <input
                      type="submit"
                      defaultValue="Get a free appointment"
                      data-wait="Please wait..."
                      className="btn-primary width-100 w-button"
                      onClick={() => {
                        handleSubmit();
                      }}
                    />
                  )}
                </div>
              </div>
              <div className="success-message contact-form w-form-done">
                <div>
                  <div className="line-rounded-icon success-message-check large">
                    
                  </div>
                  <h3 className="display-3">Thank you</h3>
                  <div>
                    Thanks for reaching out. We will get back to you soon.
                  </div>
                </div>
              </div>
              <div className="error-message w-form-fail">
                <div>Oops! Something went wrong</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <footer className="footer-wrapper">
        <div className="bg-neutral-100">
          <div className="container-default w-container">
            <div
              data-w-id="59a7f434-e96a-a890-4742-603a27851dbc"
              className="footer-bottom v1"
            >
              <a
                id="w-node-_59a7f434-e96a-a890-4742-603a27851dbe-27851db9"
                href={APP_ROUTES.DASHBOARD}
                className="footer-logo-wrapper mg-bottom-0 w-inline-block"
              >
                <img src={logoimg} alt="" />
              </a>
              <p className="color-neutral-800 mg-bottom-0">
                Copyright © CureBay
              </p>
            </div>
          </div>

          <Dialog
            visible={open}
            // position="top"
            position={isMobile() ? "top" : "center"}
            header={<div className="font-bold text-lg">Consent Form</div>}
            style={{ height: "500px" }}
            className="sm:w-4/5 w-full"
            onHide={handleClose}
            footer={
              <div className="sm:mx-10 sm:my-10 my-3 flex">
                <button
                  className="btn-primary width-100 w-button w-[119px] h-[49px] "
                  style={{ paddingTop: "11px" }}
                  onClick={handleAgree}
                >
                  Accept
                </button>
                <button
                  className="btn-primary width-100 w-button w-[119px] h-[49px]"
                  style={{ paddingTop: "11px" }}
                  onClick={handleDisagree}
                >
                  Decline
                </button>
              </div>
            }
            draggable={false}
            resizable={false}
          >
          <div id = "contentContainer" dangerouslySetInnerHTML={{ __html: subscriptionConsent }} />
          {/* <iframe src= {subscriptionConsent} /> */}
          </Dialog>
        </div>
      </footer>
    </>
  );
};

export default AppointmentNew;
