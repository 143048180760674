import React, { useState, useEffect } from "react";
import login from "./image/Login image.png";
// import Footer from "../homepage/footer";
import Footer from "../../components/webflow/postloginfooter";
import { useNavigate } from "react-router-dom";
import { APP_ROUTES } from "../../constants/approutes";
import { fetchConsent, signUp } from "../../store/actions/login";
import http from "../../../src/store/services/httpcommon"

import MobileFooter from "./../homepage/mobileFooter";
import {
  Card,
  Typography,
  TextField,
  Button,
  IconButton,
  Icon,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  Tab,
} from "@mui/material";
import logo from "../../assets/images/Web Logo.png";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import loginmob from "../../assets/images/loginmob.png";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import "../../index.css";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import "aos/dist/aos.css";
import Aos from "aos";
import Consent from "../login/consent";

function InputWithIcon({ type, name, onChange, onKeyDown }) {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <div className="relative">
      <input
        name={name}
        type={type === "password" ? (showPassword ? "text" : "password") : type}
        onChange={onChange}
        onKeyDown={onKeyDown}
        required
        className="w-full py-2 pr-10 pl-3 border rounded-[132px] shadow-sm focus:outline-none focus:bg-password-gray  sm:text-sm "
      />
      {type === "password" && (
        <button
          type="button"
          onClick={() => setShowPassword(!showPassword)}
          className="absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
        >
          {showPassword ? (
            <VisibilityIcon className="h-5 w-5" />
          ) : (
            <VisibilityOffIcon className="h-5 w-5" />
          )}
        </button>
      )}
    </div>
  );
}

const Index = () => {
  const navigate = useNavigate();
  const nameRegex = /^[A-Za-z\s]+$/;
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const phoneRegex =
    /^\+?\d{1,4}[-.\s]?\(?\d{1,4}\)?[-.\s]?\d{6,}(?=\D*\d\D*$)/;
  const wspRegex = /^\+?\d{1,3}\s?\d{1,14}$/;
  const passwordRegex =
    /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+{}[\]:;<>,.?~])(?!.*\s).{8,16}$/;
  const [Next, setNext] = useState(false);
  const [err, setErr] = useState("");
  const [err1, setErr1] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  const [screen, setscreen] = useState(window.innerWidth);
  const [isConsentChecked, setIsConsentChecked] = useState(false);
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState("1");
  const [subscriptionConsent, setSubscriptionConsent] = useState()
  const [termsandcond, setTermsandcond] = useState()
 
  // if (emailRegex.test(inputValue)) {
  //     setForm("email");
  //   } else if (phoneRegex.test(inputValue)) {
  //     setForm("phone");
  //   }

  useEffect(() => {
    const updateWindowDimensions = () => {
      const newWidth = window.innerWidth;
      setscreen(newWidth);
    };
    window.addEventListener("resize", updateWindowDimensions);
    return () => window.removeEventListener("resize", updateWindowDimensions);
  }, []);
  const handleCheck = () => {
    setIsChecked(!isChecked);
  };
  const [userDetails, setUserDetails] = useState({
    Name: "",
    Email: "",
    Password: "",
    ConfirmPassword: "",
  });

  const handleDailog = () => {
    setOpen(true);
  };

  const handleAgree = () => {
    setIsConsentChecked(true);
    setOpen(false);
  };
  const handleDisagree = () => {
    setIsConsentChecked(false);
    setOpen(false);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleNext = () => {
    if (!nameRegex.test(userDetails?.Name)) {
      setErr1("Enter a valid name");
    } else if (!isChecked) {
      if (
        emailRegex.test(userDetails?.Email) &&
        phoneRegex.test(userDetails?.ContactNumber?.mobileNumber) &&
        wspRegex.test(userDetails?.wpcontactnumber?.wspNo)
      ) {
        // Check for +91 country code and 10-digit number
        if (
          userDetails?.ContactNumber?.countryCode === "+91" &&
          userDetails?.ContactNumber?.mobileNumber.length !== 10
        ) {
          setErr1("Please enter a 10-digit phone number for +91 country code");
          setNext(false);
        } else if (
          userDetails?.ContactNumber?.countryCode === "+1" &&
          userDetails?.ContactNumber?.mobileNumber.length !== 10
        ) {
          setErr1("Please enter a 10-digit phone number for +1 country code");
          setNext(false);
        } else {
          setNext(true);
          setErr1("");
        }
      } else {
        setErr1(
          !isChecked
            ? !phoneRegex.test(userDetails?.ContactNumber?.mobileNumber)
              ? "Enter a valid Phone no1"
              : !wspRegex.test(userDetails?.wpcontactnumber?.wspNo)
              ? "Enter a valid WhatsApp Number"
              : "Enter a valid Email id"
            : !phoneRegex.test(userDetails?.ContactNumber?.mobileNumber)
            ? "Enter a valid Phone no2"
            : "Enter a valid Email id"
        );
        setNext(false);
      }
    } else {
      if (
        emailRegex.test(userDetails?.Email) &&
        phoneRegex.test(userDetails?.ContactNumber?.mobileNumber)
      ) {
        // Check for +91 country code and 10-digit number
        if (
          userDetails?.ContactNumber?.countryCode === "+91" &&
          userDetails?.ContactNumber?.mobileNumber.length !== 10
        ) {
          setErr1("Please enter a 10-digit phone number for +91 country code");
          setNext(false);
        } else if (
          userDetails?.ContactNumber?.countryCode === "+1" &&
          userDetails?.ContactNumber?.mobileNumber.length !== 10
        ) {
          setErr1("Please enter a 10-digit phone number for +1 country code");
          setNext(false);
        } else {
          setNext(true);
          setErr1("");
        }
      } else {
        setErr1(
          !isChecked
            ? !phoneRegex.test(userDetails?.ContactNumber?.mobileNumber)
              ? "Enter valid Phone no3"
              : !wspRegex.test(userDetails?.wpcontactnumber?.wspNo)
              ? "Enter valid Whattsaap Number"
              : "Enter valid Email id"
            : !phoneRegex.test(userDetails?.ContactNumber?.mobileNumber)
            ? "Enter valid Phone no4"
            : "Enter valid Email id"
        );
        setNext(false);
      }
    }
  };

  const onSubmitClick = () => {
    async function submitform() {
      // if (!isConsentChecked) {
      //   setErr("Please accept Terms and Conditions");
      //   return;
      // } else {
      //   setErr("");
      // }
      if (passwordRegex.test(userDetails?.Password)) {
        const res = await signUp({
          firstName: userDetails?.Name,
          countryCode: userDetails?.ContactNumber?.countryCode,
          mobile: userDetails?.ContactNumber?.mobileNumber,
          email: userDetails?.Email?.toLowerCase(),
          password: userDetails?.Password,
          confirmPassword: userDetails?.ConfirmPassword,
          whatsAppNumber: !isChecked
            ? userDetails?.wpcontactnumber?.wspNo
            : userDetails?.ContactNumber?.mobileNumber,
          usrConsent: isConsentChecked ? 1 : 0,
          // whatsAppNoCountryCode:
          //   userDetails?.wpcontactnumber?.whatsAppNoCountryCode,
          whatsAppNoCountryCode: !isChecked
            ? userDetails?.wpcontactnumber?.whatsAppNoCountryCode
            : userDetails?.ContactNumber?.countryCode,
        });

        if (res.status === 200 && res?.data?.responseCode === "200") {
          setErr(res?.data?.responseMessage);
          setTimeout(() => {
            navigate(APP_ROUTES.LOGIN); // Replace APP_ROUTES.LOGIN with the actual route for the login page
          }, 5000);
        } else {
          setErr(res?.data?.responseMessage);
        }

        window.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
        window.dataLayer.push({
          event: "sign_up",
          ecommerce: {
            value: 100,
            items: [
              {
                firstName: userDetails?.Name,
                countryCode: userDetails?.ContactNumber?.countryCode,
                mobile: userDetails?.ContactNumber?.mobileNumber,
                email: userDetails?.Email?.toLowerCase(),
                password: userDetails?.Password,
                confirmPassword: userDetails?.ConfirmPassword,
                whatsAppNumber: !isChecked
                  ? userDetails?.wpcontactnumber?.wspNo
                  : userDetails?.ContactNumber?.mobileNumber,
                usrConsent: isConsentChecked ? 1 : 0,
                // whatsAppNoCountryCode:
                //   userDetails?.wpcontactnumber?.whatsAppNoCountryCode,
                whatsAppNoCountryCode: !isChecked
                  ? userDetails?.wpcontactnumber?.whatsAppNoCountryCode
                  : userDetails?.ContactNumber?.countryCode,
              },
            ],
          },
        });
      } else {
        setErr(
          "Password Must contain a min of 8 and max of 16 character, Must contain at least one uppercase and a numeral and Must contain at least one special character"
        );
      }
    }

    submitform();
  };
  console.log(
    userDetails?.ContactNumber?.mobileNumber,
    userDetails?.ContactNumber,
    "number"
  );
  console.log(
    userDetails?.wpcontactnumber?.wspNo,
    userDetails?.wpcontactnumber,
    "wpnumber"
  );
  console.log(userDetails);

  const handleInputChange = (e, countryData) => {
    if (countryData) {
      const name = "ContactNumber";
      const { dialCode, format } = countryData;

      setUserDetails((prevUserDetails) => ({
        ...prevUserDetails,
        [name]: {
          countryCode: format.charAt(0) + dialCode,
          mobileNumber: e.slice(dialCode.length).trim(),
        },
      }));
    } else {
      const { name, value } = e?.target;

      setUserDetails((prevUserDetails) => ({
        ...prevUserDetails,
        [name]: value,
      }));
    }
  };
  const handleInputChangewpnumber = (e, countryData) => {
    if (countryData) {
      const name = "wpcontactnumber";
      const { dialCode, format } = countryData;

      setUserDetails((prevUserDetails) => ({
        ...prevUserDetails,
        [name]: {
          whatsAppNoCountryCode: format.charAt(0) + dialCode,
          wspNo: e.slice(dialCode.length).trim(),
        },
      }));
    }
  };

  const [consent, setConsent] = useState();
  useEffect(() => {
    async function fetchConsentForm() {
      const res = await fetchConsent();
      if (res.status === 200 && res?.data) {
        setConsent(res?.data?.data[0]);
      }
    }
    fetchConsentForm();
  }, []);

  const handleConsentCheck = () => {
    setIsConsentChecked(!isConsentChecked);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  useEffect(() =>{

    let urls = [
    
      `${process.env.REACT_APP_NEWBASEURL}ConsentVersion/list/filter?userType=TC_W_PP`,
      
      `${process.env.REACT_APP_NEWBASEURL}ConsentVersion/list/filter?userType=TC_W_TU`
    ]
    let requests = urls.map(url => http.get(url));
    Promise.allSettled(requests)
  .then(responses => {
    console.log(responses ,responses[0].value.data.data[0], "sdvsdguvgsdvds")
    // if(responses[1].status == 'fulfilled'){
    //   if(responses[1].value.data.data[0].privacyVersionNumber !== responses[0].value.data.data[0].versionNumber || responses[1].value.data.data[0].tcVersionNumber !== responses[0].value.data.data[1].versionNumber){
    //     setIsVisible(true)
    //   } else if(openConsent){
    //     setIsVisible(true)
    //   }
     
    // }
   
    let info = {
      privacyPdf: responses[0].value.data.data[0].consentFile,
      privacyVersion: responses[0].value.data.data[0].versionNumber,
      tcPdf: responses[0].value.data.data[1].consentFile,
      tcVersion: responses[0].value.data.data[1].versionNumber

    }
    setSubscriptionConsent(info)
  }).catch(err =>{
    console.log(err, "sdvhsdouvdsuovds")
    if(err?.status == 404){
      
    }
  })
   
   
  },[])

  console.log(subscriptionConsent,`${process.env.REACT_APP_IMAGE_URL}${subscriptionConsent?.tcPdf}`,"subscription")



  return (
    <div className="backgroundimgdiv select-none xs-overflow-x-hidden  md:absolute md:top-10 w-full">
      <div
        style={{
          position: "absolute",
          maxWidth: "1400px",
          width: "100%",
          // height: "100%",
          display: "flex",
          flexDirection: "column",
          // backgroundImage: `linear-gradient(175.92deg, rgba(34, 68, 119, 0.47) 6.62%,rgba(57, 97, 158, 0.06) 92.88%)`,
        }}
      >
        <div className="w-full flex flex-col items-center pt-10">
          <h1
            className="text-center sm:text-[35px] text-3xl pl-5 sm:pl-0 pr-5 sm:pr-0 leading-10 sm:leading-none font-poppins font-bold sm:mt-10 tracking-normal text-white sm:text-[#0B4881]
         
          sm:pt-10 pt-12"
          >
            Protect the hands that raised you
          </h1>
          <p className="sm:block hidden text-[#0B4881] text-center  sm:leading-8 sm:text-[20px] text-sm  font-poppins font-normal sm:mb-0 mb-5">
            Round the clock access to our advanced healthcare solutions
            <br></br> Curated for the unique needs of the elderly
          </p>
        </div>
        <div className="flex sm:justify-end justify-center sm:pr-16 sm:pl-0 pl-8 pr-8 py-6 max-w-1400">
          <Card
            sx={{
              borderColor: "#396DDD52",
              borderWidth: "0.5px",
              borderRadius: "20px",
              height: "565px",
              width: "434px",
              backgroundColor: "#F4F8FE",
            }}
            className="max-w-1400"
          >
            <div className="grid justify-start px-6 pt-2 pb-2">
              <img
                className="px-14  h-auto sm:pb-4 pb-[22px] sm:w-[329px]"
                src={logo}
                alt="curebay"
              ></img>

              <p className="flex flex-row justify-between font-bold text-[25px] font-poppins text-[#004171]">
                Sign Up
                {Next && (
                  <span>
                    {err === "SignUp Successfully" ? (
                      ""
                    ) : (
                      <IconButton onClick={() => setNext(false)}>
                        <Icon sx={{ color: "#000" }}>arrow_back</Icon>
                      </IconButton>
                    )}
                  </span>
                )}
              </p>

              {Next === false && (
                <div>
                  <div className="grid">
                    <label className="text-brand-lightBlack pt-3 capitalize text-[14px]">
                      Name<span className="text-red-500"> *</span>
                    </label>
                    <input
                      name="Name"
                      className="focus:bg-password-gray focus:outline-none px-2 border h-10 rounded-[132px] w-full"
                      type="text"
                      value={userDetails?.Name}
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                  <div className="grid py-2">
                    <label className="text-brand-lightBlack font-poppins pt-1 capitalize text-[14px]">
                      Mobile Number<span className="text-red-500"> *</span>
                    </label>
                    <div className="flex items-center">
                      <PhoneInput
                        country={"us"}
                        enableSearch={true}
                        onChange={handleInputChange}
                        value={`${userDetails?.ContactNumber?.countryCode} ${userDetails?.ContactNumber?.mobileNumber}`}
                      />
                    </div>
                    {/* <label className="text-brand-lightBlack font-poppins pt-1 capitalize">
                                            Mobile Number<span className="text-red-500">*</span>
                                        </label> */}
                    {/* <div className="flex items-center">
                                            <select
                                                className="focus:outline-none px-2 border rounded-l-sm h-10"
                                                required
                                                name="countryCode"
                                                value={userDetails.countryCode}
                                                onChange={(e) => { handleInputChange(e) }}
                                            >
                                                <option value="+1">+1 (USA)</option>
                                                <option value="+44">+44 (UK)</option>
                                                <option value="+91">+91 (INDIA)</option>
                                            </select>
                                            <input
                                                name="MobileNumber"
                                                className="focus:bg-password-gray focus:outline-none px-2 border h-10 rounded-r-sm  ml-2 w-full"
                                                onChange={(e) => { handleInputChange(e) }}
                                                required
                                                maxLength={10}
                                                type="text"
                                                minLength={10}
                                                placeholder="Enter your mobile number"
                                            />
                                        </div> */}
                  </div>

                  <div className="grid py-2">
                    <div className="flex justify-start items-start font-poppins ">
                      <input
                        className="mr-3"
                        type="checkbox"
                        checked={isChecked}
                        onChange={handleCheck}
                      ></input>
                      <label className="font-poppins text-[12px] ">
                        Whatsapp number is the same as the Mobile number
                        <span className="text-red-500"> *</span>
                      </label>
                    </div>
                    {!isChecked ? (
                      // <input
                      //   name="wspNo"
                      //   className="focus:bg-password-gray focus:outline-none px-2 border h-10 rounded-sm w-full"
                      //   type="text"
                      //   onChange={(e) => {
                      //     handleInputChange(e);
                      //   }}
                      //   value={userDetails?.wspNo}
                      // />

                      <PhoneInput
                        country={"us"}
                        enableSearch={true}
                        onChange={handleInputChangewpnumber}
                        value={`${userDetails?.wpcontactnumber?.whatsAppNoCountryCode} ${userDetails?.wpcontactnumber?.wspNo}`}
                      />
                    ) : (
                      <></>
                    )}
                  </div>
                  <div className="grid pt-2 pb-6">
                    <label className="text-brand-lightBlack capitalize font-poppins text-[14px] ">
                      Email<span className="text-red-500"> *</span>
                    </label>
                    <input
                      name="Email"
                      className="focus:bg-password-gray focus:outline-none px-2 border h-10 rounded-[132px]  w-full"
                      type="text"
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          handleNext();
                        }
                      }}
                      onChange={(e) => handleInputChange(e)}
                      value={userDetails?.Email}
                      required
                    />
                  </div>

             

    
                </div>
              )}
              {Next && (
                <div>
                  <div className="grid pt-2 pb-6 ">
                    <label className=" text-brand-lightBlack pl-2 font-poppins text-[14px]">
                      Password<span className="text-red-500">*</span>
                    </label>
                    {/* <input name="Password" className="border-2 h-10 rounded-md" type="password" onChange={(e) => { handleInputChange(e) }} required></input> */}
                    <InputWithIcon
                      type="password"
                      name="Password"
                      onChange={(e) => {
                        handleInputChange(e);
                      }}
                    />
                  </div>
                  <div className="grid  pb-6 ">
                    <label className=" text-brand-lightBlack pl-1 font-poppins text-[14px]">
                      Confirm Password<span className="text-red-500">*</span>
                    </label>
                    <InputWithIcon
                      type="password"
                      name="ConfirmPassword"
                      onChange={(e) => {
                        handleInputChange(e);
                      }}
                      onKeyDown={(e) => {
                        console.log(e.key);
                        if (e.key === "Enter") {
                          onSubmitClick();
                        }
                      }}
                    />
                  </div>
                  <div className="flex justify-start items-start pb-6">
                    <input
                      className="mt-2 mr-3"
                      type="checkbox"
                      checked={isConsentChecked}
                      onChange={handleConsentCheck}
                    ></input>
                    Read and Sign{" "}
                    <span
                      onClick={handleDailog}
                      className="text-seegreen font-bold underline cursor-pointer pl-1"
                    >
                      {" "}
                      Consent Form{" "}
                    </span>{" "}
                  </div>
                  {!err && !err1 && (
                    <div>
                      <p className="text-black-600 font-normal text-xs  text-center tracking-widest">
                        Ensure your password is 8-16 characters long and
                        contains at least one uppercase letter, one numeral, and
                        one special character.
                      </p>
                      <br />
                    </div>
                  )}
                </div>
              )}

              {err1 && (
                <span className="text-red-600 font-normal text-xs pb-4 text-center tracking-widest">
                  {err1}
                </span>
              )}
              {err && (
                <span
                  className={`${
                    err === "SignUp Successfully"
                      ? "text-green-500 "
                      : "text-red-600 "
                  }  font-normal text-xs pb-4 text-center tracking-widest`}
                >
                  {err}
                </span>
              )}

              {err === "SignUp Successfully" ? (
                <Button
                  variant="contained"
                  onClick={() => {
                    navigate(APP_ROUTES.LOGIN);
                  }}
                >
                  Go to Login
                </Button>
              ) : (
                <button
                  type="submit"
                  className="bg-[#004171] focus:outline-none px-2 border h-10 rounded-[132px] w-full text-white"
                  variant="contained"
                  onClick={() => {
                    Next ? onSubmitClick() : handleNext();
                  }}
                >
                  {Next ? "Submit" : "Next"}
                </button>
              )}

              {err !== "SignUp Successfully" && (
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontWeight: 700,
                    fontFamily: "sans-serif",
                    paddingTop: "2rem",
                    textAlign: "center",
                  }}
                >
                  Already have an account ?
                  <span
                    className="text-deep-blue cursor-pointer "
                    onClick={() => navigate(APP_ROUTES.LOGIN)}
                  >
                    {" "}
                    Log In
                  </span>
                </Typography>
              )}
            </div>
          </Card>
        </div>
      </div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
        maxWidth={"md"}
      >
        <DialogTitle id="alert-dialog-title">
          <p className="w-full text-center">Consent Form</p>{" "}
          {/* {consent?.attributes?.ThirdComponent?.name} */}
        </DialogTitle>
        <DialogContent
          sx={{
            borderWidth: 2,
            borderColor: "divider",
            marginX: "3rem",
            padding: "3rem",
          }}
        >
          <Box
            sx={{
              width: "100%",
              height: "18rem",
              typography: "body1",
              margin: "0rem",
            }}
          >
            <TabContext value={value}>
              <Box sx={{ borderBottom: 1, borderColor: "#000000" }}>
                <TabList
                  onChange={handleChange}
                  sx={{
                    "& .Mui-selected": {
                      color: "#66B889 !important",
                    },
                  }}
                  TabIndicatorProps={{
                    style: { backgroundColor: "#66B889" },
                  }}
                >
                  <Tab
                    label="Terms and Condition"
                    value="1"
                  />
                  <Tab
                    label="Privacy and Policy"
                    value="2"
                  />
                </TabList>
              </Box>

              <TabPanel value="1">
                <div className="text-justify">
                <iframe
                           height="100vh"
                           width="68px"
                          className="border rounded cursor-pointer h-96 w-full"
                           src={`${process.env.REACT_APP_IMAGE_URL}${subscriptionConsent?.tcPdf}`}
                        />

                </div>
              </TabPanel>
              <TabPanel value="2">
                <div className="text-justify">
                <iframe
                           height="95px"
                           width="68px"
                          className="border rounded cursor-pointer h-96 w-full"
                           src={`${process.env.REACT_APP_IMAGE_URL}${subscriptionConsent?.privacyPdf}`}
                        />
                </div>
              </TabPanel>
            </TabContext>
          </Box>
        </DialogContent>
        <DialogActions>
          {/* <Button onClick={handleClose}>Disagree</Button>
                                    <Button onClick={handleClose} autoFocus>
                                        Agree
                                    </Button> */}
          <div className="mx-10 my-10">
            <button
              className="text-chathams-blue-200 mr-4 border-chathams-blue-200 border-2 px-3 py-2 rounded-md hover:text-white hover:bg-chathams-blue-200 font-semibold w-28 "
              onClick={handleDisagree}
            >
              Disagree
            </button>
            <button
              className="text-chathams-blue-200 border-chathams-blue-200 border-2 px-3 py-2 rounded-md hover:text-white hover:bg-chathams-blue-200 font-semibold w-28"
              onClick={handleAgree}
            >
              Agree
            </button>
          </div>
        </DialogActions>
      </Dialog>
      {/* <img className="w-full" src={login} alt={login} id="login" style={{}} />
            {screen > 500 ? <Footer i={1} /> : <MobileFooter />} */}

      <img
        className="w-full sm:h-[920px] h-full sm:block hidden "
        src={login}
        alt={login}
        id="login"
        style={{}}
      />
      <img
        className="w-full h-screen sm:hidden block"
        src={loginmob}
        alt={login}
        id="login"
      />
      <div className="hidden sm:block">
        {/* {screen > 500 ? <Footer i={1} /> : <MobileFooter />} */}
        <Footer />
      </div>
    </div>
  );
};

export default Index;
