import React from "react";
import DashboardSidemenu from "./dashboard-sidemenu";
import { ArrowForwardIos } from "@mui/icons-material";
import {
  Button,
  Card,
  CardContent,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
  Modal,
} from "@mui/material";
import Tab from "@mui/material/Tab";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Box from "@mui/material/Box";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import TabContext from "@mui/lab/TabContext";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import Profileimg from "../../images/video-call-icon.svg";
import axios from "axios";
import dashboardservice from "../../store/services/dashboardservice";
import homepageservices from "../../store/services/homepageservices";
import mydetailsservice from "../../store/services/mydetailsservice";
import { fetchPlans } from "../../store/actions/myprofile";
import MultiChartLine from "../common/MultiChatLine";
import { APP_ROUTES } from "../../constants/approutes";
import { useNavigate, useLocation } from "react-router-dom";
import hearthealth from "../../assets/images/Heart Health.svg";
import newtrends from "../../assets/images/new trends.svg";
import diabatic from "../../assets/images/Diabetic Care.svg";
import general from "../../assets/images/General Health.svg";
import Womens from "../../assets/images/Womens' Health.svg";
import ortho from "../../assets/images/Ortho Care.svg";
import medibag from "../../assets/images/medibag.png";
import morninmed from "../../assets/images/morningmedicine.png";
import drug from "../../assets/images/drug.png";
import afternoon from "../../assets/images/afternoon.png";
import night from "../../assets/images/night.png";
import med from "../../assets/images/nomed.png";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { IconButton as MuiIconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import moment from "moment";
import { useSnackbar } from "../common/snackbar";
import { styled } from "@mui/material/styles";
import ChartLine from "../common/ChartLine";
import Buy_Medicine_image from "../../../src/assets/images/Buy_Medicine_image.png";
import Heart_Health_Diagnosis from "../../../src/assets/images/Heart_Health_Diagnosis icon.svg";
import { fetchCarePlan, fetchUserList } from "../../store/actions/dashboard";
import Footer from "../../components/webflow/postloginfooter";
import dayjs from "dayjs";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import pulseImg from "../../assets/images/pulse.svg";
import mark from "../../assets/images/markk.png";
import image from "../../assets/images/img.png";
import weightImg from "../../assets/images/BMI icon.svg";
import ROC from "./requestChange";
import Pagination from "@mui/material/Pagination";
import { CalculateAge, formatDateToDDMMYYYY } from "../common";
import MobileFooter from "./../homepage/mobileFooter";

import Stack from "@mui/material/Stack";
import Responsivemenu from "./responsive-menu";
import FooterMobileVersion from "../homepage/FooterMobileVersion";
import { toast } from "react-toastify";
import ReactPaginate from "react-paginate";
import labicon from "../../assets/images/labicon.svg";
import frame from "../../assets/images/Frame.png";

import prescriptionicon from "../../assets/images/prescriptionicon.png";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import labrecordicon from "../../assets/images/Lab Report illustration.svg";
import Prescriptionicon from "../../assets/images/Prescription illustration.svg";
import doctoricon from "../../assets/images/Doctor consultation illustration.svg";
import medicineicon from "../../assets/images/Medicine schedule illustration.svg";
import { fetchConsent, consentData } from "../../store/actions/login";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { updateUserData } from "../../store/actions/useractions";
import http from "../../store/services/httpcommon";

import {
  TabView as PrimereacttabView,
  TabPanel as Primereacttabpanel,
} from "primereact/tabview";
import { Dialog as Primereactdialog } from "primereact/dialog";
import { userConsent } from "../../store/services/dashboardservice";

const IconButton = styled(MuiIconButton)({
  backgroundColor: "white",
  borderColor: "#66B889 !important",
  borderWidth: "1px !important",
  border: "solid",
  color: "#66B889",
  "&:hover": {
    backgroundColor: "#66B889",
    color: "white",
  },
});

const Index = () => {
  const dispatch = useDispatch();
  const urlSearchParams = new URLSearchParams(window.location.search);
  const tokenFromURL = urlSearchParams.get("refresh_token");
  const { snackbarMessage, setSnackbarMessage } = useSnackbar();
  const loginObjStr = localStorage.getItem("loginObj");
  const loginObj = JSON.parse(loginObjStr);
  const userObj = loginObj?.[0];
  let bp = 0;
  const [BParray, setBParray] = useState([]);

  const [BPAvg, setBPAvg] = useState(0);
  const [BPMin, setBPMin] = useState(0);
  const [BPMax, setBPMax] = useState(0);
  const [max1, setmax1] = useState(null);
  const [myVitalgivendate, setMyVitalgivendate] = useState(null);

  const userCode = userObj?.userCode
    ? userObj.userCode
    : userObj?.parentCode
    ? userObj.parentCode
    : null;
  const userCodenew = userObj?.userCode ? userObj.userCode : null;
  const navigate = useNavigate();
  const location = useLocation();
  const stateData = location.state;
  const [url, setUrl] = useState("");
  const [value, setValue] = React.useState("1");
  const [patientData, setpatientData] = useState({});
  const [labDetails, setlabData] = useState();
  const [presDetails, setprescriptionData] = useState();
  const [postAppointment, setpostData] = useState(0);
  const [medSchedule, setMedSchedule] = useState([]);
  const [videos, setHealthVideos] = useState([]);
  const [excercise, setExcercise] = useState([]);
  const [diet, setDiet] = useState([]);

  const [users, setUserLists] = useState([]);
  const [healthCare, setHealthCare] = useState([]);
  const [vitals, setVitals] = useState([]);
  // const [Weight, setBMI] = useState("");
  const [BMI, setBMI] = useState("");
  const [pulse, setPulse] = useState("");
  const [currentPageNu, setcurrentPageNu] = useState(1);
  const [currentPageNulab, setcurrentPageNulab] = useState(1);
  const [currentPageDoctConsult, setcurrentPageDoctConsult] = useState(1);
  const [labLimit, setLabLimit] = useState(1);
  const [presLimit, setpresLimit] = useState(1);
  const [doctConsultLimit, setDoctsConsultLimit] = useState(1);

  const [age1, setAge1] = React.useState(1);
  const [care, setCare] = useState();
  const [age, setAge] = React.useState(0);
  const [age2, setAge2] = useState("Last 6 Months");
  const [myPlan, setMyPlan] = useState();
  const [user, setUser] = useState();
  const [selectedDates, setSelectedDates] = useState(null);
  const [previousDate, setPreviousDate] = useState(null);
  const [latest, setLatest] = useState();
  const [latestDate, setLatestDate] = useState();
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [selectedDateIndex, setSelectedDateIndex] = useState(null);
  const [datesEnabled, setDatesEnabled] = useState(true);
  const [vitalRange, setVitalrange] = useState();
  const [bloodPressue, setBloodpressure] = useState(true);
  const [bloodSugar, setBloodsugar] = useState(false);
  const [SpO2, setspo2] = useState();
  const [pulseRate, setPR] = useState();
  const [isROCOpen, setIsROCOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [Active, setActiv] = useState();
  const [screen, setscreen] = useState(window.innerWidth);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 3; // Number of items to display per
  const [currentPagenew, setCurrentPagenew] = useState(1);
  const [currentPagepost, setCurrentPagepost] = useState(1);
  const [currentPagelab, setCurrentPagelab] = useState(1);
  const [selecteexpirydDates, setSelectedexpiryDates] = useState(null);
  const [open, setOpen] = useState(false);
  const [selectedName, setSelectedName] = useState("");
  const [allowedToSeePrescrp, setAllowedToSeePrescp] = useState(true);
  const [allowedToSeeLabRep, setAllowedToSeeLabPrep] = useState(true);
  const [privacyPolicyConsentcompany, setprivacyPolicyConsentcompany] =
    useState("");
  const [termsAndConditionConsentcompany, settermsAndConditionConsentcompany] =
    useState("");
  const [privacyPolicyConsentuser, setprivacyPolicyConsentuser] = useState("");
  const [termsAndConditionConsentuser, settermsAndConditionConsentuser] =
    useState("");
  const [consentform, setConsentform] = useState("");
  const [consentOpen, setConsentOpen] = useState(false);
  const [consentopennew, setConsentpennew] = useState(false);
  const [consentdata, setConsentdata] = useState();
  const [privacyPolicyConsentuserdata, setprivacyPolicyConsentuserdata] =
    useState("");
  const [
    termsAndConditionConsentuserdata,
    settermsAndConditionConsentuserdata,
  ] = useState("");
  const [tcPdf, setTcPdf] = useState("");
  const [privacyPdf, setPrivacyPdf] = useState("");
  const [consentInfo, setConsentInfo] = useState({
    privacyPdf: "",
    privacyVersion: "",
    tcPdf: "",
    tcVersion: "",
  });

  useEffect(() => {
    async function fetchConsentForm() {
      const res = await fetchConsent();
      if (res.status === 200 && res?.data) {
        setConsentdata(res?.data?.data[0]);
      }
    }
    fetchConsentForm();
  }, []);
  useEffect(() => {
    // if (stateData === "dashboard") {
    getVersion();
    // }
  }, [dispatch, userCode]);

  function getVersion() {
    if (userObj?.userCode == null) {
      let urls = [
        `${process.env.REACT_APP_NEWBASEURL}ConsentVersion/list/filter?userType=TC_W_PP`,
        `${process.env.REACT_APP_NEWBASEURL}PatientConsent/list/filter?patientId=${userObj?.code}&status=1&sourceChannel=Totalcare`,
      ];
      // let requests = urls.map((url) => axios.get(url));
      let requests = urls.map((url) => http.get(url));
      Promise.allSettled(requests)
        .then((responses) => {
          console.log(responses, "sdvsdguvgsdvds");
          if (responses[1].status == "fulfilled") {
            // if (
            //   responses[1].value.data.data[0].privacyVersionNumber !==
            //     responses[0].value.data.data[0].versionNumber ||
            //   responses[1].value.data.data[0].tcVersionNumber !==
            //     responses[0].value.data.data[1].versionNumber
            // ) {
            //   setConsentpennew(true);
            // } else
            if (
              responses[1].value.data.data[0].privacyPolicyConsent == 0 ||
              responses[1].value.data.data[0].termsAndConditionConsent == 0
            ) {
              setConsentpennew(true);
            }
          } else if (responses[1].status == "rejected") {
            setConsentpennew(true);
          }
          let info = {
            privacyPdf: responses[0].value.data.data[0].consentFile,
            privacyVersion: responses[0].value.data.data[0].versionNumber,
            tcPdf: responses[0].value.data.data[1].consentFile,
            tcVersion: responses[0].value.data.data[1].versionNumber,
          };
          setConsentInfo(info);
        })

        .catch((err) => {
          console.log(err, "sdvhsdouvdsuovds");
          if (err?.status == 404) {
            setConsentpennew(true);
          }
        });
    } else {
      const axiosRequests = async () => {
        let requests = [
          {
            method: "GET",
            url: `${process.env.REACT_APP_NEWBASEURL}ConsentVersion/list/filter?userType=Patient`,
          },

          dispatch(
            dashboardservice.userConsent({ userCode: userObj?.userCode })
          ),
          // {
          //   method: "POST",
          //   url: `${process.env.REACT_APP_MEDICARE_BASEURL}userConsent/list`,
          //   payload: {
          //     userCode: userObj?.userCode,
          //   },
          // },
        ];

        let promises = requests.map(({ method, url, payload }) => {
          if (method === "GET") {
            return axios.get(url);
          } else if (method === "POST") {
            return axios.post(url, payload);
          }
          return null; // Handle other HTTP methods if needed
        });

        try {
          const responses = await Promise.allSettled(promises);
          console.log(responses, "sdvsdguvgsdvds");
          console.log(
            responses,
            responses[1].status,
            responses[1].value.data.succesObject.privacyVersionNumber,
            responses[0].value.data.data[0].versionNumber,
            responses[1].value.data.succesObject.tcVersionNumber,
            responses[0].value.data.data[1].versionNumber,
            "sdvsdguvgsdvdskk"
          );

          if (responses[1].status == "fulfilled") {
            if (
              responses[1].value.data.succesObject.privacyVersionNumber !==
                responses[0].value.data.data[0].versionNumber ||
              responses[1].value.data.succesObject.tcVersionNumber !==
                responses[0].value.data.data[1].versionNumber
            ) {
              setConsentpennew(true);
            } else if (
              responses[1].value.data.succesObject.privacyPolicyConsent == 0 ||
              responses[1].value.data.succesObject.termsAndConditionConsent == 0
            ) {
              setConsentpennew(true);
            }
          } else if (responses[1].status == "rejected") {
            setConsentpennew(true);
          }
          let info = {
            privacyPdf: responses[0].value.data.data[0].consentFile,
            privacyVersion: responses[0].value.data.data[0].versionNumber,
            tcPdf: responses[0].value.data.data[1].consentFile,
            tcVersion: responses[0].value.data.data[1].versionNumber,
          };
          setConsentInfo(info);
        } catch (error) {
          console.error("Error:", error);
        }
      };

      axiosRequests();
    }
  }

  // useEffect(() => {
  //   const getVersion = async () => {
  //     try {
  //       const response = await fetch(
  //         `${process.env.REACT_APP_NEWBASEURL}ConsentVersion/list/filter?userType=PATIENT`,
  //         {
  //           method: "GET",
  //         }
  //       );

  //       if (!response.ok) {
  //         throw new Error(`HTTP error! status: ${response.status}`);
  //       }

  //       const data = await response.json();
  //       console.log(
  //         data,
  //         data?.data[0]?.versionNumber,
  //         data?.data[1]?.versionNumber,
  //         "ddffgghhkjbnkj"
  //       );
  //       setprivacyPolicyConsentcompany(data?.data[0]?.versionNumber);
  //       settermsAndConditionConsentcompany(data?.data[1]?.versionNumber);
  //       // setprivacyPolicyConsentcompany("v8");
  //       // settermsAndConditionConsentcompany("v8");

  //       setTcPdf(data?.data[1]?.consentFile);
  //       setPrivacyPdf(data?.data[0]?.consentFile);
  //     } catch (e) {
  //       console.log("Error fetching data:", e);
  //     }
  //   };

  //   getVersion();
  // }, []);
  // useEffect(() => {
  //   const patientConsentList = async () => {
  //     // console.log( userObj?.userCode,userObj?.code,"rdfgh")
  //     if (userObj?.userCode == null) {
  //       try {
  //         const response = await fetch(
  //           `${process.env.REACT_APP_NEWBASEURL}PatientConsent/list/filter?patientId=${userObj?.code}&status=1`,
  //           {
  //             method: "GET",
  //           }
  //         );
  //         const data = await response.json();
  //         console.log(data?.status, "ffghjj");
  //         setConsentform(data?.status);
  //         // Data not found
  //         setprivacyPolicyConsentuser(data?.data[0]?.privacyVersionNumber);
  //         settermsAndConditionConsentuser(data?.data[0]?.tcVersionNumber);
  //       } catch (e) {
  //         console.log("Error fetching data:", e);
  //       }
  //     } else {
  //       let payload = {
  //         userCode: userObj?.userCode,
  //       };

  //       console.log("Payload:", payload);
  //       const res = await axios.post(
  //         `${process.env.REACT_APP_MEDICARE_BASEURL}userConsent/list`,
  //         payload
  //       );
  //       console.log(
  //         res?.data?.succesObject?.status,
  //         res?.data?.succesObject?.privacyVersionNumber,
  //         res?.data?.succesObject?.tcVersionNumber,
  //         res?.data?.succesObject,
  //         "userConsent"
  //       );
  //       setConsentform(res?.data?.succesObject?.status);
  //       setprivacyPolicyConsentuser(
  //         res?.data?.succesObject?.privacyVersionNumber
  //       );
  //       settermsAndConditionConsentuser(
  //         res?.data?.succesObject?.tcVersionNumber
  //       );
  //       setprivacyPolicyConsentuserdata(
  //         res?.data?.succesObject?.privacyPolicyConsent
  //       );
  //       settermsAndConditionConsentuserdata(
  //         res?.data?.succesObject?.termsAndConditionConsent
  //       );
  //     }
  //   };
  //   // if (stateData == "dashboard") {
  //   patientConsentList();
  //   // }
  // }, []);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClosenew = () => {
    setOpen(false);
  };

  useEffect(() => {
    const updateWindowDimensions = () => {
      const newWidth = window.innerWidth;
      setscreen(newWidth);
    };
    window.addEventListener("resize", updateWindowDimensions);
    return () => window.removeEventListener("resize", updateWindowDimensions);
  }, []);
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const handlePageChangenew = (pageNumber) => {
    setCurrentPagenew(pageNumber);
  };
  const handlePageChangepostAppointment = (pageNumber) => {
    setCurrentPagepost(pageNumber);
  };
  const handlePageChangelab = (pageNumber) => {
    setCurrentPagelab(pageNumber);
  };
  console.log(labDetails, "postAppointment");
  // dayjs.extend(localizedFormat);
  const specialoption = {
    plugins: {
      title: {
        display: true,
        align: "start",
        text: "Bitcoin Mining Difficulty",
      },
    },
  };

  // const initialDate = dayjs('2023-05-30').format('DD/MM/YYYY');
  // const formatDate = (date) => {
  //     return dayjs(date).format('DD/MM/YYYY');
  // };

  const handleCloseROC = async () => {
    setIsROCOpen(false);
  };
  const [close, setClose] = useState(false);
  const handleClose = () => {
    setClose(true);
  };
  const handleCloseconsent = () => {
    setConsentpennew(false);
  };

  const handleAgree = () => {
    setConsentpennew(false);
    consentformversion();
  };
  const handleDisagree = () => {
    setConsentpennew(false);
    withdrawconsentformversion();
  };
  const consentformversion = async () => {
    if (userObj?.userCode == null) {
      const payload = {
        createdBy: "CUREBAYADMIN",
        privacyPolicyConsent: 1,
        sourceChannel: "Totalcare",
        status: 1,
        termsAndConditionConsent: 1,
        type: "P",
        patientId: userObj?.code,
        privacyVersionNumber: consentInfo.privacyVersion,
        tcVersionNumber: consentInfo.tcVersion,
      };

      console.log("Payload:", payload);
      const postResponse = await http.post(
        `${process.env.REACT_APP_NEWBASEURL}PatientConsent/addPatientConsent`,
        payload
      );

      console.log("POST Request Response for test", postResponse);
      if (postResponse.status === 200) {
        setSnackbarMessage("Consent Save Successfully.", "success");
      }
    } else {
      const payload = {
        createdBy: "CUREBAYADMIN",
        privacyPolicyConsent: 1,
        sourceChannel: "Totalcare",
        status: 1,
        termsAndConditionConsent: 1,
        type: "U",
        userCode: userObj?.userCode,
        privacyVersionNumber: consentInfo.privacyVersion,
        tcVersionNumber: consentInfo.tcVersion,
      };
      const postResponse = await http.post(
        `${process.env.REACT_APP_MEDICARE_BASEURL}userConsent/save`,
        payload
      );
      if (postResponse.status === 200) {
        setSnackbarMessage(postResponse?.data?.responseMessage, "success");
      }
    }
  };

  const withdrawconsentformversion = async () => {
    if (userObj?.userCode == null) {
      const payload = {
        createdBy: "CUREBAYADMIN",
        privacyPolicyConsent: 0,
        sourceChannel: "Totalcare",
        status: 1,
        termsAndConditionConsent: 0,
        type: "P",
        patientId: userObj?.code,
        privacyVersionNumber: consentInfo.privacyVersion,
        tcVersionNumber: consentInfo.tcVersion,
      };

      console.log("Payload:", payload);
      const postResponse = await http.post(
        `${process.env.REACT_APP_NEWBASEURL}PatientConsent/addPatientConsent`,
        payload
      );

      console.log("POST Request Response for test", postResponse);
      if (postResponse.status === 200) {
        logout();
      }
    } else {
      const payload = {
        createdBy: "CUREBAYADMIN",
        privacyPolicyConsent: 0,
        sourceChannel: "Totalcare",
        status: 1,
        termsAndConditionConsent: 0,
        type: "U",
        userCode: userObj?.userCode,
        privacyVersionNumber: consentInfo.privacyVersion,
        tcVersionNumber: consentInfo.tcVersion,
      };
      const postResponse = await http.post(
        `${process.env.REACT_APP_MEDICARE_BASEURL}userConsent/save`,
        payload
      );
      if (postResponse.status === 200) {
        logout();
      }
    }
  };

  const logout = () => {
    dispatch(updateUserData({}));
    localStorage.clear();
    navigate(APP_ROUTES.DASHBOARD);
    if (
      typeof window !== "undefined" &&
      typeof window.location !== "undefined"
    ) {
      window.location.reload();
    }
  };
  const onUserChange1 = (value) => {
    // console.log('onUserChange1 called with value:', value);
    setAge2(value);
    // getPatientVitals();
  };

  const datePickerStyles = {
    input: {
      width: "100%",
      padding: "0.375rem 0.75rem",
      fontSize: "0.875rem",
      lineHeight: "3",
      color: "#495057",
      backgroundColor: "#fff",
      backgroundClip: "padding-box",
      border: "1px solid #ced4da",
      borderRadius: "0.25rem",
      transition:
        "border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out",
      boxShadow: "0 2px 28px rgba(0,0,0,.1)",
    },
  };

  const filePath = `${process.env.REACT_APP_IMAGE_URL}`;

  useEffect(() => {
    getUpcoming();
    getPost();
    // if (bloodPressue || bloodSugar) {
    getPatientVitals();
    // }
    getcarePlan(userCode);
    getMedSchedule(userCode);
  }, [age, bloodPressue, bloodSugar, age1, age2]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangenew = (event, newValue) => {
    setValue(newValue);
  };

  const handleChange2 = (event) => {
    setAge1(event.target.value);
    for (const value of healthCare) {
      if (value?.carePlanId === age1) {
        const date = dayjs(
          value?.subscriptionDetailsVo?.startDate,
          "DD/MM/YYYY"
        ).toDate();
        setSelectedDates(date);
        setPreviousDate(date);
        if (value?.subscriptionDetailsVo?.dateValidation === "Y")
          setDatesEnabled(true);
        else setDatesEnabled(false);
        break;
      }
    }
  };

  useEffect(() => {
    for (const value of healthCare) {
      if (value?.carePlanId === age1) {
        const date = dayjs(
          value?.subscriptionDetailsVo?.startDate,
          "DD/MM/YYYY"
        ).toDate();
        const expirydate = dayjs(
          value?.subscriptionDetailsVo?.expiryDate,
          "DD/MM/YYYY"
        ).toDate();
        setSelectedDates(date);
        setSelectedexpiryDates(expirydate);
        setPreviousDate(date);
        if (value?.subscriptionDetailsVo?.dateValidation === "Y") {
          setDatesEnabled(true);
        } else {
          setDatesEnabled(false);
        }
        break;
      }
    }
  }, [healthCare, age1]);

  const handleDateChange = (date, index) => {
    // const updatedDates = [...selectedDates];
    // updatedDates[index] = { date: dayjs(date).format('YYYY-MM-DD 00:00:00') };
    setSelectedDates(date);
    setConfirmDialogOpen(true);
  };

  const handleConfirmChange = async (e) => {
    const date = new Date(selectedDates);
    const timezoneOffset = date.getTimezoneOffset() * 60000; // Get the time zone offset in milliseconds
    const adjustedDate = new Date(date.getTime() - timezoneOffset); // Adjust the date by subtracting the time zone offset
    const isoDateString = adjustedDate.toISOString();
    const formattedDate = isoDateString.split("T")[0];
    const finalDateString = `${formattedDate} 00:00:00`;
    setConfirmDialogOpen(false);
    setSelectedDateIndex(null);
    let subSeqId;
    for (const value of healthCare) {
      if (value?.carePlanId === age1) {
        subSeqId = value?.subscriptionDetailsVo?.subSeqId;
        break;
      }
    }
    let payload = {
      date: finalDateString,
      subSeqId: subSeqId,
    };
    const res = await dashboardservice.updateEffectiveStartDate(payload);
    if (res?.status === 200 && res?.data?.responseCode === "200") {
      setSnackbarMessage(res.data.responseMessage, "success");
      getcarePlan();
    } else if (res?.data?.responseCode === "500") {
      setSelectedDates(previousDate);
      setSnackbarMessage(res.data.responseMessage, "error");
    } else {
      setSelectedDates(previousDate);
      setSnackbarMessage("Please try after sometime", "error");
    }
  };

  const handleCancelChange = () => {
    // Close the confirmation dialog without making any changes
    setConfirmDialogOpen(false);
    setSelectedDateIndex(null);
    setSelectedDates(previousDate);
  };
  const handleCancelChangenew = () => {
    setConsentOpen(false);
  };

  useEffect(() => {
    async function fetchCares() {
      const res = await fetchCarePlan({
        userCode: userCode,
        patientcode: age,
      });
      if (res?.status === 200 && res?.data) {
        setCare(res?.data);
        setAge1(res?.data?.succesObject[0]?.id);
      }
    }
    async function fetchMyPlanDetails() {
      const myPlan = await fetchPlans({
        userCode: userCode,
        patientCode: age,
      });

      if (myPlan.status === 200 && myPlan?.data) {
        setMyPlan(myPlan?.data);
        console.log(myPlan.data);
        // const temp = myPlan?.data?.succesObject?.patientVOList.find(e=>e.code==))
        const temp = myPlan?.data?.succesObject?.patientVOList.filter(
          (p) => p.code == age
        );
        if (temp) {
          setUser(temp[0]);
        }
      }
      // const carePlansResponse = await getOurCareplans();
      // if (carePlansResponse.status === 200 && carePlansResponse?.data?.succesObject) {
      //     setCarePlans(carePlansResponse.data.succesObject);
      // }
    }
    fetchMyPlanDetails();
    fetchCares();
  }, [age]);
  const getBParray2 = (a) => {
    setBParray([]);
    setBloodsugar(true);
    setBloodpressure(false);
  };
  const getBParray = (a) => {
    setBloodpressure(true);
    setBloodsugar(false);
  };
  const onUserChange = (code) => {
    getMedSchedule(code);
    setAge(code);
    getActive(code);
    setSelectedDates(null);
    setSelectedexpiryDates(null);

    const selectedUser = users.find((user) => user.code === code);
    if (selectedUser) {
      setSelectedName(selectedUser.name);
    } else {
      setSelectedName("");
    }
  };
  const breadcrumbItems = [{ label: "Home" }, { label: "Dashboard" }];
  const videoCall = (e) => {
    setClose(false);
    const header = {
      secretKey: "CcJYfbsgItHpTQPFr5lg",
    };
    const data = {
      clientCode: "CCM@@202!",
      userType: "HOST",
      meetingKey: e.id,
      memberName: Active.firstName || "Patient",
      memberEmail: Active.email ? Active.email : "patient@gmail.com",
      memberMobile: Active.mobile ? Active.mobile : "1234567890",
    };
    axios
      .post(
        "https://cb.maggieplus.com/admin-api/client/meeting/authentication",
        data,
        { headers: header }
      )
      .then((result) => {
        setUrl(result.data.response.url);

        //  setTimeout(() => {
        document.getElementById("ccc").style.display = "block";
        //  }, 6000);
      })
      .catch((err) => {
        console.log("Err", err);
      });
  };
  //  useEffect(() => {
  //         getActive('')
  //     },[age]);
  useEffect(() => {
    getHealthTips();
    getUserLists(userCode);

    console.log(videos);
  }, []);
  useEffect(() => {
    // Find the default user's name based on the default code when the component mounts
    const defaultUser = users.find((user) => user.code === age);
    if (defaultUser) {
      setSelectedName(defaultUser.name);
    } else {
      setSelectedName(""); // If no default user found, clear the name
    }
  }, [age]);

  const getUserLists = async (userCode) => {
    const reqObj = {
      code: userCode,
      email: userObj?.email,
      mobile: userObj?.mobile,
    };
    const res = await dashboardservice.getUserList(reqObj);
    setUserLists(res?.data?.succesObject);
    setAge(res?.data?.succesObject[0]?.code);
    getActive(res?.data?.succesObject[0]?.code);
  };

  const getMedSchedule = async () => {
    // const reqObj = { patientCode: (userObj?.code?.trim()) }
    const reqObj = { patientCode: age ? age : userCode ? userCode : null };

    const res = await dashboardservice.getMedicineSchedule(reqObj);
    setMedSchedule(res.data.succesObject.patientDrugPrescription);
    console.log(medSchedule, "okkkkk");
  };

  const getcarePlan = async () => {
    let payload = { patientCode: age };

    let res = await homepageservices.getcarePlan(payload);
    setHealthCare(res?.data?.succesObject);
  };
  const getHealthTips = async () => {
    const res = await homepageservices.getHealthTip();
    setHealthVideos(res?.data?.data[0]?.attributes?.health[0]?.videos);
    setDiet(res?.data?.data[0]?.attributes?.diet[0]?.content);
    setExcercise(res?.data?.data[0]?.attributes?.exercise[0]?.content);
  };
  const getPatientVitals = async () => {
    let today = new Date();
    let startDate;
    let yesterday = new Date();

    if (age2 === "Last 1 Week") {
      startDate = new Date(today.getTime() - 7 * 24 * 60 * 60 * 1000);
    } else if (age2 === "Last 1 Month") {
      startDate = new Date(today.getTime() - 30 * 24 * 60 * 60 * 1000);
    } else if (age2 === "Last 3 Months") {
      startDate = new Date(today.getTime() - 90 * 24 * 60 * 60 * 1000);
    } else if (age2 === "Last 6 Months") {
      startDate = new Date(today.getTime() - 180 * 24 * 60 * 60 * 1000);
    } else if (age2 === "Last 1 Year") {
      startDate = new Date(today.getTime() - 365 * 24 * 60 * 60 * 1000);
    }
    yesterday.setDate(yesterday.getDate());

    let payload = {
      patientCode: age ? age : userCode ? userCode : null,
      startDate: formatDateToDDMMYYYY(startDate),
      endDate: formatDateToDDMMYYYY(new Date(yesterday.getTime())),
    };
    console.log(age2, payload, "startDate");

    let res = await mydetailsservice.getVitals(payload);
    setVitalrange(res?.data?.succesObject?.vitalsDetails);

    let BParray1 = [];
    let spo2 = [];
    let pulseRT = [];
    // let weightdata = [];
    let bmidata = [];

    res?.data?.succesObject?.vitalsDetails?.BMI.forEach((element) => {
      bmidata.push(element.value);
    });
    const bmilength = res?.data?.succesObject?.vitalsDetails?.BMI.length;
    // console.log(weightlength, "weight");

    setBMI(bmidata[bmilength - 1]);

    res?.data?.succesObject?.vitalsDetails?.SPo2.forEach((element) => {
      spo2.push(element.value);
    });
    setspo2(spo2);
    res?.data?.succesObject?.vitalsDetails?.HeartRate.forEach((element) => {
      pulseRT.push(element.value);
    });
    const pulselength =
      res?.data?.succesObject?.vitalsDetails?.HeartRate.length;
    setPR(pulseRT);
    setPulse(pulseRT[pulselength - 1]);
    const filteregivendate = [];
    if (bloodPressue == true) {
      res?.data?.succesObject?.vitalsDetails?.BP.forEach((element) => {
        // bp = bp + element.value
        BParray1.push(element.data);
        const date = element.givenDate.split(" ")[0];
        filteregivendate.push(date);
      });
      setBParray(BParray1);
      setmax1(Math.max(BParray1));

      setMyVitalgivendate(filteregivendate);
    } else {
      res?.data?.succesObject?.vitalsDetails?.BloodGlucose.forEach(
        (element) => {
          bp = bp + element.value;
          BParray1.push(element.value);
          const date = element.givenDate.split(" ")[0];
          filteregivendate.push(date);
        }
      );
      setBParray(BParray1);

      setmax1(Math.max(BParray1));

      setMyVitalgivendate(filteregivendate);
    }
    // setBPAvg(bloodPressue == true ? Math.ceil(bp / res?.data?.succesObject?.vitalsDetails?.BP.length) : Math.ceil(bp / res?.data?.succesObject?.vitalsDetails?.BloodGlucose.length))
    // console.log(Math.min(BParray1))
    // setBPMin(Math.min.apply(null, BParray1))
    // setBPMax(Math.max.apply(null, BParray1))
    // setWeight(vitalRange?.Weight[0]?.value)
    // setPulse(res?.data?.succesObject?.vitalsDetails?.PulseRate?.[0]?.value)
  };

  console.log(BParray, "BParrayaa");
  useEffect(() => {
    getPatientVitalsRange();
    console.log(vitals, "carep");
  }, [age, bloodPressue, bloodSugar]);

  useEffect(() => {
    getpresList();
  }, [age, currentPageNu]);
  useEffect(() => {
    getLablist();
  }, [age, currentPageNulab]);

  useEffect(() => {
    getUpcoming();
  }, [age, age1]);

  const getLablist = () => {
    let payload = {
      patientId: age ? age : null,
      docmentRequired: "Y",
      // pageNo: currentPageNulab,
      // pageLimit: 5,
    };
    mydetailsservice.getmylablist(payload).then((res) => {
      setlabData(res.data.succesObject);
      setLabLimit(Math.ceil(res.data.totalRecords / 5));
    });
    console.log(labLimit);
  };

  const getPatientVitalsRange = () => {
    let payload = {
      patientCode: age ? age : null,
    };
    mydetailsservice.getVitalsRange(payload).then((res) => {
      // setlabData(res.data.succesObject)
      // setVitalrange(res.data.succesObject)
      // setBPAvg(bloodPressue == true ? Math.ceil(bp / res?.data?.succesObject?.vitalsDetails?.BP.length) : Math.ceil(bp / res?.data?.succesObject?.vitalsDetails?.BloodGlucose.length))
      // console.log(Math.min(BParray1))
      setBPMin(
        (bloodSugar == true
          ? res.data.succesObject[0].minimumNormalRange
          : res.data.succesObject[3].minimumNormalRange
        ).toFixed(0)
      );
      setBPMax(
        (bloodSugar == true
          ? res.data.succesObject[0].maximumNormalRange
          : res.data.succesObject[3].maximumNormalRange
        ).toFixed(0)
      );
      setBPAvg(
        (bloodSugar == true
          ? (res.data.succesObject[0].minimumNormalRange +
              res.data.succesObject[0].maximumNormalRange) /
            2
          : (res.data.succesObject[3].minimumNormalRange +
              res.data.succesObject[3].maximumNormalRange) /
            2
        ).toFixed(0)
      );
    });
  };

  const handlePagination = (event, value) => {
    setcurrentPageNu(value);
  };
  const handlePagination1 = (event, value) => {
    setcurrentPageNulab(value);
  };
  const handlePagination2 = (event, value) => {
    setcurrentPageDoctConsult(value);
  };

  const viewPrescription = (e) => {
    let payload = {
      appointmentId: e.appointmentId,

      visitSummary: "Y",
      status: "1",

      stampRequired: "Y",

      documentRequired: "Y",

      vitalRequired: "Y",
    };
    mydetailsservice
      .getpresDocName(payload)
      .then((res) => {
        // if (res.data.succesObject[0]?.patientDrugPrescriptionList[0]?.docName) {
        //     let docName = res.data.succesObject[0].patientDrugPrescriptionList[0].docName
        //     window.open(filePath + docName, '_blank');
        // }
        // else {
        if (res?.data?.succesObject?.length) {
          let epres = res.data.succesObject[0];
          navigate(
            APP_ROUTES.EPRECRIPTION,
            { state: { epres: epres, dashboard: "dashboard" } },
            { replace: true }
          );
        } else {
          toast.error(
            "Prescription is not available at the moment. Please try after sometime",
            {
              position: toast.POSITION.TOP_CENTER,
            }
          );
        }
        // }
      })
      .catch((error) => {
        console.error("Failed to fetch prescription data:", error);
      });
  };

  const getUpcoming = () => {
    const temp = care?.succesObject?.filter((p) => p.id == age1);
    console.log(temp);
    let payload = {
      patientCode: age ? age : null,
      userCode: userCode,
      appointmentType: 1,
      // pageNo: currentPageNu,
      // pageLimit: 5,
      // planId: age1 ? age1 : null,
    };
    dashboardservice.getappointment(payload).then((res) => {
      setpatientData(res.data.succesObject);

      const temp =
        res?.data?.succesObject?.length > 0
          ? res?.data?.succesObject?.reduce((acc, curr) => {
              return curr.whenAppointment < acc.whenAppointment ? curr : acc;
            }, res.data.succesObject[0])
          : null;

      setLatest(temp);
      setLatestDate(temp?.whenAppointment);
    });
  };
  console.log(patientData, "567");
  const getPost = () => {
    const temp = care?.succesObject?.filter((p) => p?.id == age1);
    let payload = {
      patientCode: age ? age : null,
      userCode: userCode,
      appointmentType: 2,
      planId: age1 ? age1 : null,
      // pageNo: currentPageDoctConsult,
      // pageLimit: 3,
    };
    dashboardservice.getappointment(payload).then((res) => {
      setpostData(res.data.succesObject);
      setDoctsConsultLimit(Math.ceil(res.data.totalRecords / 5));
    });
  };
  // const initialPrescriptionData = [
  //   {
  //     id: 2866,
  //     userId: "AEJYVSSK080322",
  //     patientId: "RUnaP7235p",
  //     diagnositicNotes: "string",
  //     quantity: 10,
  //     visitSymptoms: "asdfg",
  //     userSalutation: "ghjk",
  //     userName: "fhghjb",
  //     // ... other properties
  //   },
  //   {
  //     id: 2855,
  //     userId: "AEJYVSSK080322",
  //     patientId: "RUnaP7235p",
  //     diagnositicNotes: "string",
  //     quantity: 10,
  //     visitSymptoms: "asfhghjdfg",
  //     userSalutation: "ghjhgjk",
  //     userName: "jghj",
  //     // ... other properties
  //   },
  //   {
  //     id: 2855,
  //     userId: "AEJYVSSK080322",
  //     patientId: "RUnaP7235p",
  //     diagnositicNotes: "string",
  //     quantity: 10,
  //     visitSymptoms: "jk",
  //     userSalutation: "asdf",
  //     userName: "mko",
  //     // ... other properties
  //   },
  //   {
  //     id: 2855,
  //     userId: "AEJYVSSK080322",
  //     patientId: "RUnaP7235p",
  //     diagnositicNotes: "string",
  //     quantity: 10,
  //     visitSymptoms: "pppp",
  //     userSalutation: "ghjhgghjjk",
  //     userName: "bhn",
  //     // ... other properties
  //   },
  //   {
  //     id: 2855,
  //     userId: "AEJYVSSK080322",
  //     patientId: "RUnaP7235p",
  //     diagnositicNotes: "string",
  //     quantity: 10,
  //     visitSymptoms: "ert",
  //     userSalutation: "uuu",
  //     userName: "bbb",
  //     // ... other properties
  //   },
  // ];
  const getpresList = () => {
    let payload = {
      patientId: age ? age : null,
      userCode: userCode,
      // pageNo: currentPageNu,
      // pageLimit: 3,
    };
    mydetailsservice.getmypPrescriptionlist(payload).then((res) => {
      // res.data.succesObject.length = 3
      setprescriptionData(res.data.succesObject);
      // setprescriptionData(initialPrescriptionData);

      setpresLimit(Math.ceil(res.data.totalRecords / 3));

      console.log(res.data.succesObject);
    });
  };
  console.log(presDetails, "presDetails");
  const onUserClick = (code) => {
    getHealthTips();
    // getUserLists(code)
    getMedSchedule(code);
  };

  const getActive = (e) => {
    let payload = {
      code: e ? e : age,
    };
    mydetailsservice.getmyListapi(payload).then((res) => {
      console.log(res?.data?.succesObject);
      setActiv(res?.data?.succesObject);
    });
  };
  const handleRequestChange = (item) => {
    setSelectedItem(item);
    setIsROCOpen(true);
  };

  const [countbeforebreakfast, setCountbeforebreakfast] = useState(0);

  const [countafterbreakfast, setCountafterbreakfast] = useState(0);

  const [countbeforelunch, setCountbeforelunch] = useState(0);

  const [countafterlunch, setCountafterlunch] = useState(0);

  const [countbeforedinner, setCountbeforedinner] = useState(0);

  const [countafterdinner, setCountafterdinner] = useState(0);

  useEffect(() => {
    let totalCount = 0;

    let totalCountafterbreakfast = 0;

    let totalCountbeforelunch = 0;

    let totalCountafterlunch = 0;

    let totalCountbeforedinner = 0;

    let totalCountafterdinner = 0;

    // Assuming medSchedule is the data array you provided

    medSchedule.forEach((obj, index) => {
      const time = obj?.dosage?.split("-").map(Number);

      if (time[0] === 1) {
        totalCount++;
      }
    });

    medSchedule.forEach((obj, index) => {
      const time = obj?.dosage?.split("-").map(Number);

      if (time[0] === 1) {
        totalCountafterbreakfast++;
      }
    });

    medSchedule.forEach((obj, index) => {
      const time = obj?.dosage?.split("-").map(Number);

      if (time[1] === 1) {
        totalCountbeforelunch++;
      }
    });

    medSchedule.forEach((obj, index) => {
      const time = obj?.dosage?.split("-").map(Number);

      if (time[1] === 1) {
        totalCountafterlunch++;
      }
    });

    medSchedule.forEach((obj, index) => {
      const time = obj?.dosage?.split("-").map(Number);

      if (time[2] === 1) {
        totalCountbeforedinner++;
      }
    });

    medSchedule.forEach((obj, index) => {
      const time = obj?.dosage?.split("-").map(Number);

      if (time[2] === 1) {
        totalCountafterdinner++;
      }
    });

    setCountafterbreakfast(totalCountafterbreakfast);

    setCountbeforebreakfast(totalCount);

    setCountbeforelunch(totalCountbeforelunch);

    setCountafterlunch(totalCountafterlunch);

    setCountbeforedinner(totalCountbeforedinner);

    setCountafterdinner(totalCountafterdinner);
  }, [medSchedule]);
  const [result_obj, setResult_obj] = useState({});
  let today = new Date();
  let startDate1 = new Date(today.getTime() - 180 * 24 * 60 * 60 * 1000);
  const [selectedToDate, setSelectedToDate] = useState(new Date());
  const [selectedFromDate, setSelectedFromDate] = useState(startDate1);
  const viewHealthGraph = (key, value) => {
    let props1 = {
      name: key,
      fromDate: selectedFromDate,
      toDate: selectedToDate,
      patient: age,
    };
    navigate(APP_ROUTES.MYHCARDS, { state: props1 }, { replace: true });
  };

  const handleAuthorization = () => {
    localStorage.setItem("selectedpatient", age);
    const authorizationUrl =
      "https://d1k6rp0c3sgwpu.cloudfront.net/#/auth?code=5fc7cb54-44bd-418c-b1a8-dffd33b29694";
    window.location.href = authorizationUrl; // Redirect the user to the authorization URL
    // const refreshToken = "6f5cd933-8600-44e9-92c4-40063740f9a7-1701843363476"
    // window.open(authorizationUrl);
  };

  const [isConsentChecked, setIsConsentChecked] = useState(false);

  const showCheckbox = () => {
    setIsConsentChecked(true);
    toast.error("Please select prescription to buy medicines");
  };

  // useEffect(() => {
  //   if (
  //     stateData == "dashboard" &&
  //     privacyPolicyConsentcompany !== "" &&
  //     termsAndConditionConsentcompany !== ""
  //   ) {
  //     if (userObj?.userCode == null) {
  //       if (consentform == 404) {
  //         setConsentpennew(true);
  //       } else if (
  //         privacyPolicyConsentcompany == privacyPolicyConsentuser &&
  //         termsAndConditionConsentcompany == termsAndConditionConsentuser
  //       ) {
  //         // setConsentOpen(false);
  //       } else {
  //         setConsentpennew(true);
  //       }
  //     } else {
  //       console.log(
  //         privacyPolicyConsentcompany == privacyPolicyConsentuser,
  //         termsAndConditionConsentcompany == termsAndConditionConsentuser,
  //         privacyPolicyConsentcompany,
  //         privacyPolicyConsentuser,
  //         termsAndConditionConsentcompany,
  //         termsAndConditionConsentuser,
  //         consentform,
  //         "ttttt"
  //       );
  //       if (consentform == 0) {
  //         setConsentpennew(true);
  //       } else if (
  //         privacyPolicyConsentuserdata == 0 ||
  //         termsAndConditionConsentuserdata == 0
  //       ) {
  //         setConsentpennew(true);
  //       } else if (
  //         privacyPolicyConsentcompany == privacyPolicyConsentuser &&
  //         termsAndConditionConsentcompany == termsAndConditionConsentuser
  //       ) {
  //         // setConsentOpen(false);
  //       } else {
  //         setConsentpennew(true);
  //       }
  //     }
  //   }
  // }, [
  //   consentform,
  //   privacyPolicyConsentcompany,
  //   termsAndConditionConsentcompany,
  //   privacyPolicyConsentuser,
  //   termsAndConditionConsentuser,
  // ]);

  // useEffect(() => {
  //   if (
  //     stateData === "dashboard" &&
  //     privacyPolicyConsentcompany !== "" &&
  //     termsAndConditionConsentcompany !== ""
  //   ) {
  //     if (userObj?.userCode === null) {
  //       if (consentform === 404) {
  //         setConsentpennew(true);
  //       } else if (
  //         privacyPolicyConsentcompany === privacyPolicyConsentuser &&
  //         termsAndConditionConsentcompany === termsAndConditionConsentuser
  //       ) {
  //         setConsentpennew(false); // If user has accepted both policies, no pending consent
  //       } else {
  //         setConsentpennew(true); // If user hasn't accepted both policies, set consent pending
  //       }
  //     } else {
  //       console.log(
  //         privacyPolicyConsentcompany === privacyPolicyConsentuser,
  //         termsAndConditionConsentcompany === termsAndConditionConsentuser,
  //         privacyPolicyConsentcompany,
  //         privacyPolicyConsentuser,
  //         termsAndConditionConsentcompany,
  //         termsAndConditionConsentuser,
  //         consentform,
  //         "ttttt"
  //       );
  //       if (consentform === 0 ||
  //         privacyPolicyConsentuserdata === 0 ||
  //         termsAndConditionConsentuserdata === 0) {
  //         setConsentpennew(true);
  //       } else if (
  //         privacyPolicyConsentcompany === privacyPolicyConsentuser &&
  //         termsAndConditionConsentcompany === termsAndConditionConsentuser
  //       ) {
  //         setConsentpennew(false);
  //       } else {
  //         setConsentpennew(true);
  //       }
  //     }
  //   }
  // }, [
  //   consentform,
  //   privacyPolicyConsentcompany,
  //   termsAndConditionConsentcompany,
  //   privacyPolicyConsentuser,
  //   termsAndConditionConsentuser,
  //   privacyPolicyConsentuserdata,
  //   termsAndConditionConsentuserdata,
  // ]);

  useEffect(() => {
    if (!userObj?.parentCode) {
      const temp = users?.filter((p) => p.code === age)[0];
      setAllowedToSeePrescp(!(temp?.consentDrRemark === 0));
      setAllowedToSeeLabPrep(!(temp?.consentMedReport === 0));
    }
  }, [age]);

  const handlenewChange = () => {
    // navigate(APP_ROUTES.CONSENT);
    setConsentOpen(false);
    setConsentpennew(true);
  };
  console.log(
    privacyPolicyConsentcompany == privacyPolicyConsentuser,
    termsAndConditionConsentcompany == termsAndConditionConsentuser,
    privacyPolicyConsentcompany,
    privacyPolicyConsentuser,
    termsAndConditionConsentcompany,
    termsAndConditionConsentuser,
    consentform,
    "tyuiii"
  );

  return (
    <>
      <ToastContainer />
      {snackbarMessage}
      <div className="md:p-8 p-4">
        <div className="flex items-center mb-5 mt-[100px]">
          <div className="hidden">
            <Responsivemenu />
          </div>
          {breadcrumbItems.map((item, index) => (
            <React.Fragment key={index}>
              <span className="text-base  leading-7 text-chathams-blue-500 cursor-pointer">
                <a>{item.label}</a>
              </span>
              {index !== breadcrumbItems.length - 1 && (
                <ArrowForwardIos
                  sx={{ fontSize: 15 }}
                  className="text-chathams-blue-500 mx-2"
                />
              )}
            </React.Fragment>
          ))}
        </div>
        <div className="flex scroll-px-2">
          <div className="w-[300px] hidden md:block h-max mr-6  border items-start justify-center rounded-md ">
            <DashboardSidemenu />
          </div>

          <div className="w-full">
            <div className="md:flex justify-start w-full md:mb-8 mb-2 ">
              <div className="flex flex-col md:mr-4 mr-2">
                <p className=" text-[#004171] text-[18px] font-semibold  mb-2">
                  Member Profile
                </p>
                <Select
                  value={age}
                  defaultValue={0}
                  onChange={(event) => onUserChange(event.target.value)}
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                  sx={{
                    height: "2 rem",
                    width: "13rem",
                    width: "100%",
                    boxShadow: "0 2px 28px rgba(0,0,0,.1)",
                  }}
                >
                  {users &&
                    users?.map((value, index) => (
                      <MenuItem value={value.code} key={index}>
                        <span className="text-[15px]">{value.name}</span>
                      </MenuItem>
                    ))}
                </Select>
              </div>

              <div className="flex flex-col md:mr-4 mr-2 mt-4 sm:mt-0">
                <span className="text-[#004171] text-[18px] font-semibold  mb-2">
                  Select Careplans
                </span>
                <Select
                  value={age1}
                  onChange={handleChange2}
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                  sx={{
                    height: "2 rem",
                    width: "13rem",
                    width: "100%",
                    boxShadow: "0 2px 28px rgba(0,0,0,.1)",
                  }}
                >
                  {care &&
                    care?.succesObject?.map((value, index) => (
                      <MenuItem value={value.id} key={index} className="w-full">
                        <span className="text-[15px]">{value.name}</span>
                      </MenuItem>
                    ))}
                </Select>
              </div>
              <div className="flex flex-col md:mr-4 mr-2 mt-6 mb-5 sm:mt-0 sm:mb-0 ">
                <p className="text-[#004171] text-[18px] font-semibold   mb-2">
                  Effective Start Date
                </p>
                <DatePicker
                  style={{ boxShadow: "0 2px 28px rgba(0,0,0,.1)" }}
                  selected={selectedDates}
                  onChange={handleDateChange}
                  dateFormat="dd/MM/yyyy"
                  disabled={datesEnabled}
                  placeholderText="DD/MM/YYYY"
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  customInput={<input style={datePickerStyles.input} />}
                  minDate={new Date()}
                  maxDate={new Date().setDate(new Date().getDate() + 30)}
                />
              </div>

              <div className="flex flex-col md:mr-4 mr-2 mt-6 mb-5 sm:mt-0 sm:mb-0 ">
                <p className="text-[#004171] text-[18px] font-semibold  mb-2">
                  Expiry Date
                </p>
                <DatePicker
                  style={{ boxShadow: "0 2px 28px rgba(0,0,0,.1)" }}
                  selected={selecteexpirydDates}
                  // onChange={handleDateChange}
                  dateFormat="dd/MM/yyyy"
                  disabled
                  placeholderText="DD/MM/YYYY"
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  customInput={<input style={datePickerStyles.input} />}
                />
              </div>
            </div>

            {/* titan integration start */}
            {/* <div className="bg-[#DFF7E5] p-[17px] mb-4">
              <span className="">
                Please Register Your Titan Smart watch
              </span>

              <button
                className="bg-white text-black  text-sm sm:text-base  rounded-md float-right py-[6px] px-[42px]"
 
                onClick={handleOpen}
              >
                Register
              </button>
            </div> */}
            {/* end */}

            {users.length <= 0 ? (
              <div
                className="flex md:p-4 p-2 justify-between items-center rounded-md bg-light-sky-blue text-white w-full md:h-16 h-14"
                style={{
                  backgroundColor: "#b3e9c1",
                }}
              >
                <p className="md:text-lg text-sm mb-0 text-[#000]">
                  Currently You don't have any member, Please Add your member
                  details.
                </p>
                {/* <button className='bg-white text-black p-2' onClick={() => navigate(APP_ROUTES.RFC, { state: latest })}>Request for change</button> */}
                <button
                  className="bg-white text-[#004171] font-semibold md:p-2 text-sm sm:text-base  rounded-[20px]"
                  onClick={() => navigate(APP_ROUTES.ADDMEMBER)}
                >
                  Add Member
                </button>
              </div>
            ) : (
              <>
                {userCodenew && (
                  <>
                    {healthCare.length <= 0 ? (
                      <div
                        className="flex md:p-4 p-2 justify-between items-center rounded-md bg-light-sky-blue text-white w-full md:h-16 h-14 "
                        style={{
                          backgroundColor: "#b3e9c1",
                        }}
                      >
                        <span className="md:text-lg text-sm text-[#000] ">
                          You currently do not have an active plan. Please
                          consider purchasing one.
                        </span>
                        {/* <button className='bg-white text-black p-2' onClick={() => navigate(APP_ROUTES.RFC, { state: latest })}>Request for change</button> */}
                        <button
                          className="bg-white text-[#004171] font-semibold md:p-2 text-sm sm:text-base  rounded-[20px] "
                          onClick={() => navigate(APP_ROUTES.BUYCAREPLAN)}
                        >
                          Buy Plan
                        </button>
                      </div>
                    ) : (
                      <></>
                    )}
                  </>
                )}

                {/* <div>
                  {healthCare.length <= 0 ? (
                    <></>
                  ) : (
                    <div className="border-2 md:px-4 px-2 md:py-2 py-0 md:h-max  mb-4 md:flex md:flex-col items-center w-full rounded-md">
                      <p className="md:text-[22px] text- sm px-4   border-b w-full py-4 text-[#1A1A1AAD]">
                        Current Package Name:
                      </p>

                      <div className="flex w-full text-[#0272DA]  sm:text-[18px]  text-sm  gap-1 md:py-4 sm:py-0 py-2 overflow-x-auto">
                        {healthCare.map((item, i) =>
                          i == healthCare.length - 1 ? (
                            <>
                              <p key={i} className="whitespace-nowrap">
                               {item?.planName }{" "}
                              </p>
                            </>
                          ) : i == healthCare.length - 2 ? (
                            <>
                              <p key={i} className="whitespace-nowrap">
                                {item?.planName} ,{" "}
                              </p>
                            </>
                          ) : (
                            <>
                              <p key={i} className="whitespace-nowrap">
                                {item?.planName},{" "}
                              </p>
                            </>
                          )
                        )}
                      </div>
                      <div className="md:grid md:grid-cols-2 md:gap-2 gap-4 justify-start items-start w-full ">
                        {healthCare.map((item) => (
                          <div className="text-chathams-blue  md:text-base text-16 mb-2  border md:w-[36rem] w-full sm:p-2 py-2 pl-2 mb-3 sm:mb-0s rounded-lg bg-[#B5DFF617]">
                            {item.planName} package expires on :
                            <span className="text-[#42BA85] text-sm md:text-16px">
                              {item.subscriptionDetailsVo.expiryDate}
                            </span>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                </div> */}
                {/* <div className="dashboardwebcarousel">
                  {healthCare.length <= 0 ? (
                    <></>
                  ) : (
                    <div className=" gap-5 sm:grid grid-cols-3">
                      {healthCare.map((item, i) => (
                        <div
                          className={`${
                            item?.planName === "General Health Care"
                              ? "bg-[#caffec75]"
                              : item?.planName === "Womens Health Care"
                              ? "bg-[#ffcaca69]"
                              : item?.planName === "Diabetes Care"
                              ? "bg-[#bcebfe8f] "
                              : item?.planName === "Ortho Care"
                              ? "bg-[#f4caff5c]"
                              : item?.planName === "Cardiac Care"
                              ? "bg-[#bacbff82]"
                              : ""
                          } flex flex-col h-[113px] sm:mb-5 rounded-lg text-newblue px-[10px]`}
                        >
                          <div className="flex mt-7 ml-3 gap-2">
                            <div className="bg-white w-12 flex justify-center rounded-md">
                              {item?.planName === "General Health Care" ? (
                                <img
                                  src={general}
                                  alt="General Health"
                                  className="p-[6px]"
                                />
                              ) : item?.planName === "Diabetes Care" ? (
                                <img
                                  src={diabatic}
                                  alt="Diabetics Care"
                                  className="p-[6px]"
                                />
                              ) : item?.planName === "Cardiac Care" ? (
                                <img
                                  src={hearthealth}
                                  alt="Cardiac Care"
                                  className="p-[6px]"
                                />
                              ) : item?.planName === "Ortho Care" ? (
                                <img
                                  src={ortho}
                                  alt="Ortho Care"
                                  className="p-[6px]"
                                />
                              ) : item?.planName === "Womens Health Care" ? (
                                <img
                                  src={Womens}
                                  alt="Womens Health Care"
                                  className="p-[6px]"
                                />
                              ) : (
                                <></>
                              )}
                            </div>
                            <div className="sm:ml-[8px]">
                              <p className="  md:text-[18px]  text-[#1A1A1A]">
                                {item?.planName}
                              </p>
                              <p className="  md:text-[16px] mt-1  text-[#1A1A1A]">
                                expires on{" "}
                                <span className="  md:text-[16px]   text-[#1A1A1A]">
                                  {item.subscriptionDetailsVo.expiryDate}
                                </span>
                              </p>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </div> */}

                {/* <div className="dashboardmobcarousel block sm:none">
                  {healthCare.length <= 0 ? (
                    <></>
                  ) : (
                    <div className="mb-[25px]">
                      <Carousel
                        autoPlay
                        interval={1000}
                        infinite
                        showStatus={false}
                        showThumbs={false}
                        showDots={true}
                      >
                        {healthCare.map((item, i) => (
                          <div
                            className={`${
                              item?.planName === "General Health Care"
                                ? "bg-[#caffec75]"
                                : item?.planName === "Womens Health Care"
                                ? "bg-[#ffcaca69]"
                                : item?.planName === "Diabetes Care"
                                ? "bg-[#bcebfe8f] "
                                : item?.planName === "Ortho Care"
                                ? "bg-[#f4caff5c]"
                                : item?.planName === "Cardiac Care"
                                ? "bg-[#bacbff82]"
                                : ""
                            } flex flex-col h-[113px] sm:mb-5 rounded-lg text-newblue px-[10px]`}
                          >
                            <div className="flex mt-7 ml-3 gap-2">
                              <div className="bg-white w-12 flex justify-center rounded-md">
                                {item?.planName === "General Health Care" ? (
                                  <img
                                    src={general}
                                    alt="General Health"
                                    className="p-[6px]"
                                  />
                                ) : item?.planName === "Diabetes Care" ? (
                                  <img
                                    src={diabatic}
                                    alt="Diabetics Care"
                                    className="p-[6px]"
                                  />
                                ) : item?.planName === "Cardiac Care" ? (
                                  <img
                                    src={hearthealth}
                                    alt="Cardiac Care"
                                    className="p-[6px]"
                                  />
                                ) : item?.planName === "Ortho Care" ? (
                                  <img
                                    src={ortho}
                                    alt="Ortho Care"
                                    className="p-[6px]"
                                  />
                                ) : item?.planName === "Womens Health Care" ? (
                                  <img
                                    src={Womens}
                                    alt="Womens Health Care"
                                    className="p-[6px]"
                                  />
                                ) : (
                                  <></>
                                )}
                              </div>
                              <div className="sm:ml-[8px]">
                                <p className="  md:text-[18px]  text-[#1A1A1A]">
                                  {item?.planName}
                                </p>
                                <p className="  md:text-[16px] mt-1  text-[#1A1A1A]">
                                  expires on{" "}
                                  <span className="  md:text-[16px]   text-[#1A1A1A]">
                                    {item.subscriptionDetailsVo.expiryDate}
                                  </span>
                                </p>
                              </div>
                            </div>
                          </div>
                        ))}
                      </Carousel>
                    </div>
                  )}
                </div> */}
              </>
            )}
            {patientData?.length > 0 ? (
              <div
                className="sm:flex md:p-4 p-2 justify-between items-center rounded-md text-white w-full md:h-16 "
                style={{
                  backgroundColor: "#b3e9c1",
                }}
              >
                <div className="flex">
                  <div className="bg-white flex justify-center h-10 w-12 rounded-md">
                    <img src={medibag}></img>
                  </div>
                  <p className="sm:text-[20px]  text-sm sm:mt-2 ml-2 mb-0 text-[#004171] font-semibold">
                    {/* Next Appointment is on {new Date(patientData?.[0]?.whenAppointment).toLocaleDateString('en-US', {
                                    year: 'numeric',
                                    month: 'long',
                                    day: 'numeric'
                                })} at {patientData?.[0]?.fromTime} with Dr. {patientData?.[0]?.name} */}
                    Next Appointment is on{" "}
                    <span className="">{latestDate}</span> with{" "}
                    <span className="">
                      {latest?.userSalutation} {latest?.name}
                    </span>
                  </p>
                </div>
                {/* <button className='bg-white text-black p-2' onClick={() => navigate(APP_ROUTES.RFC, { state: latest })}>Request for change</button> */}
                <div className="text-center sm:text-start mt-[14px] sm:mt-auto">
                  <button
                    className="bg-[#fffff8] text-[#004171] md:py-2 md:px-2 text-sm sm:text-base sm:rounded-[20px] rounded-sm px-[10px] py-[3px] sm:  "
                    onClick={() => handleRequestChange(latest)}
                  >
                    Request for change
                  </button>
                </div>
              </div>
            ) : (
              <></>
            )}
            <div className="md:mt-4 mt-2">
              <div className="flex items-center gap-3">
                <span className="text-[#004171] text-[18px] font-semibold ">
                  Overview of Vitals
                </span>
                <div>
                  <Select
                    value={age2} // Use value without defaultValue
                    onChange={(event) => onUserChange1(event.target.value)}
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                    sx={{
                      height: "3rem",
                      width: "13rem",
                      width: "100%",
                      boxShadow: "0 2px 28px rgba(0,0,0,.1)",
                      borderRadius: "22px",
                    }}
                  >
                    <MenuItem value="Last 1 Week">
                      <span className="text-[15px]">Last 1 Week</span>
                    </MenuItem>
                    <MenuItem value="Last 1 Month">
                      <span className="text-[15px]">Last 1 Month</span>
                    </MenuItem>
                    <MenuItem value="Last 3 Months">
                      <span className="text-[15px]">Last 3 Months</span>
                    </MenuItem>
                    <MenuItem value="Last 6 Months">
                      <span className="text-[15px]">Last 6 Months</span>
                    </MenuItem>
                    <MenuItem value="Last 1 Year">
                      <span className="text-[15px]">Last 1 Year</span>
                    </MenuItem>
                  </Select>
                </div>
              </div>
              <div className="md:flex md:mt-4 mt-2">
                <div
                  className="w-full h-[435px]  rounded-md"
                  // style={{ border: " 1px solid #80808073" }}
                >
                  {/* <Card
                    sx={{
                      // boxShadow: " 0px 10px 13px rgba(0, 0, 0, 0.04)",
                      border: " 1px solid #80808073",
                      width: "100%",
                      paddingX: "2%",
                      // height: "20rem",
                      borderRadius: "5px", */}
                  <div
                    // className="sm:w-1/2  sm:h-[435px] overflow-y-auto border rounded-md"
                    className="sm:w-full  sm:h-[400px] sm:mb-0 mb-5 rounded-lg"
                    style={{ border: " 1px solid #80808073" }}
                  >
                    <div className="flex md:my-4 my-2 md:mx-2 flex-col">
                      <div className="flex justify-between">
                        <div>
                          {/* <input
                          id="clinic"
                          onChange={() => getBParray("bp")}
                          checked={bloodPressue == true}
                        /> */}
                          <label
                            className="flex justify-between md:w-32  w-full md:mr-4 py-22 pl-1  text-[16px] font-medium"
                            for="care1"
                          >
                            {" "}
                            Blood Pressure{" "}
                          </label>
                        </div>
                        <div className="flex gap-3">
                          <p
                            className="md:text-base text-[20px] text-seegreen cursor-pointer "
                            onClick={() => {
                              viewHealthGraph("BP", pulse);
                            }}
                          >
                            Track Progress
                          </p>

                          <img
                            className="w-[26px] h-[20px] cursor-pointer"
                            onClick={() => {
                              viewHealthGraph("BP", pulse);
                            }}
                            src={newtrends}
                          />
                        </div>

                        {/* <input
                          type="radio"
                          id="cities"
                          onChange={() => getBParray2("bs")}
                          checked={bloodSugar == true}
                        />
                        <label
                          className=" text-brand-lightBlack text-md pl-1"
                          for="care2"
                        >
                          {" "}
                          Blood Sugar{" "}
                        </label> */}
                      </div>
                      <div className="flex justify-between mt-25">
                        {" "}
                        <div className="flex flex-col-reverse items-center text-sm	">
                          <span>Min</span>
                          <span className="text-chathams-blue text-xl	">
                            {BPMin ? BPMin : 0}
                          </span>
                        </div>
                        <div className="flex flex-col-reverse items-center text-sm	">
                          <span>Average</span>
                          <span className="text-chathams-blue text-xl	">
                            {BPAvg ? BPAvg : 0}
                          </span>
                        </div>
                        <div className="flex flex-col-reverse items-center text-sm	">
                          <span>Max</span>
                          <span className="text-chathams-blue  text-xl	">
                            {BPMax ? BPMax : 0}
                          </span>
                        </div>
                      </div>
                      <div className="mt-25">
                        {/* <ChartLine data={BParray} /> */}
                        {/* <MultiChartLine data={BParray} options={specialoption}/> */}
                        {bloodPressue ? (
                          <MultiChartLine
                            data={BParray}
                            maxvalue={max1}
                            options={specialoption}
                            givendate={myVitalgivendate}
                          />
                        ) : (
                          <ChartLine
                            data={BParray}
                            maxvalue={max1}
                            options={specialoption}
                            givendate={myVitalgivendate}
                          />
                        )}
                      </div>
                    </div>
                    {/* </Card> */}
                  </div>
                </div>

                <div className="md:flex md:w-2/5 md:flex-col w-full md:mx-4 h-[435px]">
                  <Card
                    variant="outlined"
                    elevation={1}
                    sx={{
                      // boxShadow: "0 2px 28px rgba(0,0,0,.1)",
                      width: "100%",
                      paddingX: "2%",
                      marginBottom: "2rem",
                      // height: "50%",
                      borderRadius: "5px",
                      border: " 1px solid #80808073",
                    }}
                  >
                    <div className="flex flex-col mx-2 md:my-4 my-2">
                      <div className="flex justify-between items-center">
                        <p className="md:text-xl text-[20px] mb-0">Pulse</p>
                        <img src={pulseImg} />
                      </div>
                      <div className="flex gap-2 ">
                        <p className="text-[30px] md:pt-2 text-[#0B4881] font-semibold">
                          {pulse}
                        </p>
                        <p className="mt-5 text-[#0272DA] text-[16px] ">BPM</p>
                      </div>
                      <div className="flex justify-between items-center">
                        <p
                          className="md:text-base text-[20px]  text-seegreen mt-4 cursor-pointer mb-0"
                          onClick={() => {
                            viewHealthGraph("HeartRate", pulse);
                          }}
                        >
                          Track Progress
                        </p>

                        <img
                          className="w-[26px] cursor-pointer"
                          onClick={() => {
                            viewHealthGraph("HeartRate", pulse);
                          }}
                          src={newtrends}
                        />
                      </div>
                    </div>
                  </Card>
                  <Card
                    variant="outlined"
                    elevation={1}
                    sx={{
                      // boxShadow: "0 2px 28px rgba(0,0,0,.1)",
                      width: "100%",
                      paddingX: "2%",
                      // height: "50%",
                      borderRadius: "5px",
                      border: " 1px solid #80808073",
                    }}
                  >
                    <div className="flex flex-col mx-2 md:my-4 my-2">
                      <div className="flex justify-between items-center">
                        <p className="md:text-xl text-[20px] ">BMI</p>

                        <img className="w-7" src={weightImg} />
                      </div>

                      <div className="flex gap-2 ">
                        <p className="text-[30px] md:pt-2 text-chathams-blue font-semibold ">
                          {BMI}
                        </p>
                        <p className="mt-5 text-[#0272DA] ">kg/m2</p>
                      </div>

                      <div className="flex justify-between items-center">
                        <p
                          className="md:text-base text-[20px]  text-seegreen mt-4 cursor-pointer"
                          onClick={() => {
                            viewHealthGraph("BMI", BMI);
                          }}
                        >
                          Track Progress
                        </p>

                        <img
                          className="w-[26px] cursor-pointer"
                          onClick={() => {
                            viewHealthGraph("BMI", BMI);
                          }}
                          src={newtrends}
                        />
                      </div>
                    </div>
                  </Card>
                </div>

                <div className="w-full ">
                  <div
                    // className="sm:w-1/2  sm:h-[435px] overflow-y-auto border rounded-md"
                    className="sm:w-full  sm:h-[400px] sm:mb-0 mb-5 rounded-lg"
                    style={{ border: " 1px solid #80808073" }}
                  >
                    <div className=" flex justify-between md:mx-3 mx-0 md:my-4 my-2">
                      <div className="">
                        <p
                          className="text-brand-lightBlack  text-[16px]"
                          sx={{ color: "" }}
                        >
                          Oxy Level
                        </p>
                      </div>
                      <div className="flex gap-3">
                        <p
                          className="md:text-base text-[20px]  text-seegreen cursor-pointer "
                          onClick={() => {
                            viewHealthGraph("SPo2", pulse);
                          }}
                        >
                          Track Progress
                        </p>

                        <img
                          className="w-[26px] h-[20px] cursor-pointer"
                          onClick={() => {
                            viewHealthGraph("SPo2", pulse);
                          }}
                          src={newtrends}
                        />
                      </div>
                    </div>
                    <div>
                      <div className="mx-3 my-4">
                        <div className="flex my-4 mx-2 flex-col	">
                          <div className="flex justify-between">
                            <div className="flex flex-col-reverse items-center text-sm	">
                              <span>Min SpO2%</span>
                              <span className="text-chathams-blue md:text-xl text-16px	">
                                95%
                              </span>
                            </div>
                            <div className="flex flex-col-reverse items-center text-sm	">
                              <span>Min PR</span>
                              <span className="text-navy-blue  md:text-xl text-16px	">
                                60-100
                              </span>
                            </div>
                          </div>
                          <div className="md:mt-25">
                            {/* <ChartLine data={SpO2} pulseRT={pulseRate} /> */}
                            <ChartLine
                              data={SpO2}
                              maxvalue={max1}
                              options={specialoption}
                              givendate={myVitalgivendate}
                            />
                          </div>
                        </div>{" "}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* new design start */}
              <div className="md:flex gap-8 w-full ">
                <div
                  className="sm:w-1/2  sm:h-[435px] sm:mb-0 mb-5 rounded-lg overflow-y-auto"
                  style={{ border: " 1px solid #80808073" }}
                >
                  <div className="sm:flex px-[20px] py-[20px] border-b-2">
                    <div className="sm:w-1/2 w-full">
                      <h2
                        className="pl-[7px] text-[20px]  text-[#0B4881] mb-0 font-semibold font-poppins"
                        style={{ borderLeft: "5px solid #00B2DD" }}
                      >
                        Lab Reports
                      </h2>
                    </div>

                    <div className="sm:w-1/2 w-full sm:text-right mt-5 sm:mt-0">
                      <span
                        className="text-[#004171] rounded-[23px] text-[16px] font-semibold cursor-pointer sm:px-5 px-3 py-1 sm:py-3 font-poppins"
                        style={{ border: "1px solid #004171" }}
                        onClick={() =>
                          window.open("https://curebay.com/labtests", "_blank")
                        }
                      >
                        Book Lab Test
                      </span>
                    </div>
                    <div className="sm:w-[170px] text-right -mt-7 sm:mt-0">
                      <span
                        className="text-[#004171] rounded-[23px] text-[16px] font-semibold cursor-pointer sm:px-5 px-3 py-1 sm:py-3 font-poppins"
                        style={{ border: "1px solid #004171" }}
                        onClick={() => {
                          navigate(APP_ROUTES.MYLABREPORTS);
                        }}
                      >
                        View All
                      </span>
                    </div>
                  </div>

                  {labDetails?.length == 0 ? (
                    <div className="w-full text-center align-middle mt-11">
                      <img src={labrecordicon} />
                      <p className="text-base font-medium text-[#1A1A1A] mt-2">
                        No lab reports is available right now
                      </p>
                    </div>
                  ) : (
                    ""
                  )}
                  {allowedToSeeLabRep ? (
                    <>
                      <div className="grid sm:grid-cols-2 grid-cols-1 gap-2 py-[23px] px-[15px]">
                        {" "}
                        {labDetails &&
                          labDetails?.map((lab, index) => (
                            <div
                              style={{
                                boxShadow: "rgba(0, 0, 0, 0.1) 0px 2px 28px",
                                width: "100%",
                                paddingX: "2%",
                                borderRadius: "7px",
                                padding: "12px 12px",
                              }}
                            >
                              <div>
                                <img
                                  src={frame}
                                  className="h-[39px] w-[38px]"
                                />
                                <span className="float-right">
                                  <a
                                    href={filePath + "" + lab.labReportDoc}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    <i
                                      className="fa fa-eye text-sm text-[#0272DA] cursor-pointer float-right w-[40px] text-center pt-[5px] bg-[#F6F7FA] rounded-lg sm:h-[30px] h-11"
                                      aria-hidden="true"
                                    ></i>
                                  </a>
                                </span>

                                {/* <div class="sm:w-[17%] w-10  flex items-center justify-center bg-[#F6F7FA] rounded-lg sm:h-[30px] h-11"><span>
                              <i class="fa fa-eye text-sm text-[#0272DA] cursor-pointer" aria-hidden="true"></i></span></div> */}
                              </div>
                              <div>
                                <p className="text-[#1A1A1A] text-base font-semibold mb-0 mt-[18px]">
                                  {lab.labTestCode}
                                </p>
                                <p className="text-[#1A1A1A] text-sm  mb-0 mt-[2px]">
                                  Last Lab test done on{" "}
                                  <span className="font-semibold">
                                    {" "}
                                    {lab.createdDate.split(" ")[0]}
                                  </span>
                                  <p className="text-[#1A1A1A] text-sm  mb-0 mt-[2px]">
                                    Source Channel:{" "}
                                    <span className="font-semibold">
                                      {" "}
                                      {lab.sourceChannel ==
                                      "Backoffice-Medicare"
                                        ? "Totalcare"
                                        : lab.sourceChannel == "ECLINIC"
                                        ? "ECLINIC"
                                        : lab.sourceChannel == "B2C"
                                        ? "Consumer App"
                                        : lab.sourceChannel == "Swasthya Mitra"
                                        ? "Swasthya Mitra"
                                        : ""}
                                    </span>
                                  </p>
                                </p>
                              </div>
                            </div>
                          ))}
                      </div>
                    </>
                  ) : (
                    <div className="flex text-center pt-8 px-2">
                      <p>
                        Due to parental restrictions, we regret to inform you
                        that you are not currently authorized to view the lab
                        reports.
                      </p>
                    </div>
                  )}
                </div>

                {/* prescription div */}

                <div
                  // className="sm:w-1/2  sm:h-[435px] overflow-y-auto border rounded-md"
                  className="sm:w-1/2  sm:h-[435px] sm:mb-0 mb-5 rounded-lg overflow-y-auto"
                  style={{ border: " 1px solid #80808073" }}
                  // style={{ boxShadow: "0 2px 28px rgba(0,0,0,.1)" }}
                >
                  <div className="sm:flex px-[20px] py-[20px] border-b-2">
                    <div className="sm:w-1/2">
                      <h2
                        className="pl-[7px] text-[20px]  text-[#0B4881] mb-0 font-semibold font-poppins"
                        style={{ borderLeft: "5px solid #00B2DD" }}
                      >
                        Prescriptions
                      </h2>
                    </div>

                    <div className="sm:w-1/2 sm:text-right sm:mt-0 mt-5">
                      <span
                        className="text-[#004171] rounded-[23px] text-[16px] font-semibold cursor-pointer sm:px-5 sm:py-3 px-4 py-1 font-poppins"
                        style={{ border: "1px solid #004171" }}
                        onClick={showCheckbox}
                      >
                        Buy Medicine
                      </span>
                    </div>

                    <div className="sm:w-[170px] text-right -mt-7 sm:mt-0">
                      <span
                        className="text-[#004171] rounded-[23px] text-[16px] font-semibold cursor-pointer sm:px-5 sm:py-3 px-4 py-1 font-poppins"
                        style={{ border: "1px solid #004171" }}
                        onClick={() => {
                          navigate(APP_ROUTES.MYPRESCRIPTION);
                        }}
                      >
                        View All
                      </span>
                    </div>
                  </div>
                  {presDetails?.length == 0 ? (
                    <div className="w-full text-center align-middle mt-11">
                      <img src={Prescriptionicon} />
                      <p className="text-base font-medium text-[#1A1A1A] mt-2">
                        No prescription has been prescribed yet
                      </p>
                    </div>
                  ) : (
                    ""
                  )}

                  {allowedToSeePrescrp ? (
                    <div className="grid sm:grid-cols-2 grid-cols-1 gap-4 px-[15px] py-[23px]">
                      {presDetails && (
                        <>
                          {presDetails && (
                            <>
                              {presDetails.map((pres, index) => (
                                <div className="" style={{}}>
                                  {/* 
                                    <div className="w-[90%] pl-[15px] pr-[5px]">
                                      <h2 className="sm:text-lg text-base text-[#1A1A1A] mb-0">
                                        {" "}
                                        Reason of Consultation:{" "}
                                        <span>
                                          {pres.vistConsultationsReason}
                                        </span>
                                      </h2>

                                      <span className="sm:text-base text-sm  text-[#1A1A1A]">
                                        {" "}
                                        Doctor Name:{" "}
                                        {pres.userSalutation +
                                          "" +
                                          pres.userName}
                                      </span>

                                      <p className="sm:text-base text-sm  text-[#1A1A1A] mb-0">
                                        Prescribed on{" "}
                                        <span className="">
                                          {" "}
                                          {pres.visitDate.split(" ")[0]}
                                        </span>
                                      </p>
                                    </div> */}

                                  <Card
                                    sx={{
                                      boxShadow:
                                        "rgba(0, 0, 0, 0.1) 0px 2px 28px",
                                      width: "100%",
                                      paddingX: "2%",
                                      borderRadius: "7px",
                                      padding: "8px 12px",
                                    }}
                                  >
                                    <div className="flex justify-between py-3 relative">
                                      {isConsentChecked ? (
                                        <input
                                          className="absolute left-0 "
                                          type="checkbox"
                                          onClick={() => {
                                            viewPrescription(pres);
                                          }}
                                        />
                                      ) : (
                                        ""
                                      )}
                                      <div className="sm:w-[30%] w-10  flex items-center justify-center rounded-lg sm:h-[43px] h-11">
                                        <img src={prescriptionicon} />
                                      </div>
                                      <div className="sm:w-[17%] w-10  flex items-center justify-center bg-[#F6F7FA] rounded-lg sm:h-[30px] h-11">
                                        <span>
                                          <i
                                            className="fa fa-eye text-sm text-[#0272DA] cursor-pointer"
                                            aria-hidden="true"
                                            onClick={() => {
                                              viewPrescription(pres);
                                            }}
                                          ></i>
                                        </span>
                                      </div>
                                    </div>

                                    <div className="">
                                      <h2 className="sm:text-base text-base font-semibold text-[#1A1A1A] mb-0">
                                        {" "}
                                        Reason of Consultation:{" "}
                                        <span className="font-medium text-sm">
                                          {pres.vistConsultationsReason}
                                        </span>
                                      </h2>

                                      <span className="sm:text-sm font-semibold text-sm  text-[#1A1A1A]">
                                        {" "}
                                        Doctor Name:{" "}
                                        <span className="font-medium">
                                          {pres.userSalutation +
                                            "" +
                                            pres.userName}
                                        </span>
                                      </span>

                                      <p className="sm:text-sm text-sm font-semibold text-[#1A1A1A] mb-0">
                                        Prescribed on{" "}
                                        <span className="font-medium">
                                          {" "}
                                          {pres.visitDate.split(" ")[0]}
                                        </span>
                                      </p>

                                      <p className="text-[#1A1A1A] text-sm  mb-0 mt-[2px] font-semibold">
                                        Source Channel:{" "}
                                        <span className="font-medium">
                                          {" "}
                                          {pres.sourceChannel ==
                                          "Backoffice-Medicare"
                                            ? "Totalcare"
                                            : pres.sourceChannel == "ECLINIC"
                                            ? "ECLINIC"
                                            : pres.sourceChannel == "B2C"
                                            ? "Consumer App"
                                            : pres.sourceChannel ==
                                              "Swasthya Mitra"
                                            ? "Swasthya Mitra"
                                            : ""}
                                        </span>
                                      </p>
                                    </div>
                                  </Card>
                                </div>
                              ))}
                            </>
                          )}

                          {/* {presDetails.length > 3 ? (
                          <>
                            <div class="paginationcontainer">
                              <ReactPaginate
                                previousLabel={"<"}
                                nextLabel={">"}
                                breakLabel={"..."}
                                pageCount={Math.ceil(
                                  presDetails.length / itemsPerPage
                                )}
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={5}
                                onPageChange={(selected) => {
                                  handlePageChange(selected.selected + 1);
                                }}
                                containerClassName={"pagination"}
                                subContainerClassName={"pages pagination"}
                                activeClassName={"active"}
                              />
                            </div>
                          </>
                        ) : (
                          ""
                        )} */}
                        </>
                      )}
                    </div>
                  ) : (
                    <div className="flex text-center pt-8 px-2">
                      <p>
                        Due to parental restrictions, we regret to inform you
                        that you are not currently authorized to view the
                        Prescriptions.
                      </p>
                    </div>
                  )}
                </div>
              </div>
              {/* 2nd div */}
              <div className="md:flex gap-8 w-full md:mt-8 mt-4">
                <div
                  className="sm:w-1/2 sm:h-[435px] overflow-y-auto sm:mb-0 mb-5 rounded-md"
                  style={{ border: " 1px solid #80808073" }}
                >
                  <div className="sm:flex px-[20px] py-[20px]">
                    <div className="sm:w-1/2">
                      <h2
                        className="pl-[7px] text-[20px]  text-[#0B4881] mb-0 font-semibold font-poppins"
                        style={{ borderLeft: "5px solid #00B2DD" }}
                      >
                        Doctor Consultations
                      </h2>
                    </div>

                    <div className="sm:w-1/2 sm:text-right mt-2 sm:mt-0">
                      <span
                        className="text-[#004171] rounded-[23px] text-[16px] font-semibold cursor-pointer sm:px-5 sm:py-3 px-3 py-1 font-poppins"
                        style={{ border: "1px solid #004171" }}
                        onClick={() => navigate(APP_ROUTES.APPOINTMENTS)}
                      >
                        View All
                      </span>
                    </div>
                  </div>

                  <div>
                    <Box sx={{ width: "100%", typography: "body1" }}>
                      <TabContext value={value}>
                        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                          <TabList
                            onChange={handleChange}
                            aria-label="lab API tabs example"
                          >
                            <Tab label="Upcoming" value="1" />

                            <Tab label="Past" value="2" />
                          </TabList>
                        </Box>

                        <TabPanel value="1">
                          {patientData?.length == 0 ? (
                            <div className="w-full text-center align-middle mt-2">
                              <img src={doctoricon} />
                              <p className="text-base font-medium text-[#1A1A1A] mt-2">
                                No consultation is available right now
                              </p>
                            </div>
                          ) : (
                            ""
                          )}
                          {Array.isArray(patientData) &&
                            patientData.map((item, index) => (
                              <>
                                <div
                                  className="sm:flex py-[15px]"
                                  style={{
                                    borderBottom: "1px solid #EAEAF5",
                                  }}
                                >
                                  <div className="w-[100%] flex">
                                    <div className="w-[10%] flex items-center justify-center rounded-lg sm:h-[52px]">
                                      <i
                                        class="fa fa-user-md text-[35px] text-[#2380ec]"
                                        aria-hidden="true"
                                      ></i>
                                    </div>

                                    <div className="w-[100%] pl-[15px] pr-[5px]">
                                      <div className="flex justify-between ">
                                        <h2 className=" sm:text-lg text-base text-[#1A1A1A] mb-0">
                                          {" "}
                                          {item.userSalutation} {item.name}
                                        </h2>

                                        <div className="block webrequest">
                                          <button
                                            className="float-right text-sm  text-[#0272DA]"
                                            onClick={() =>
                                              handleRequestChange(item)
                                            }
                                          >
                                            Request for Change
                                          </button>

                                          <div>
                                            {item?.consultationsType === "V" ? (
                                              <img
                                                className="float-right mt-[15px] cursor-pointer"
                                                width="25px"
                                                src={Profileimg}
                                                onClick={() => videoCall(item)}
                                              />
                                            ) : (
                                              <></>
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                      <>
                                        {item?.consultationsType === "V" ? (
                                          <p className="text-base  sm:mt-[-25px] text-[#1A1A1A] mb-0">
                                            Your call will start{" "}
                                            <span className="  sm:text-base text-sm">
                                              {item.whenAppointment +
                                                " at " +
                                                item.fromTime}
                                            </span>
                                          </p>
                                        ) : (
                                          <>
                                            <p className="text-base  text-[#1A1A1A] mb-0">
                                              Next appointment is on{" "}
                                              <span className="   sm:text-base text-sm">
                                                {item.whenAppointment +
                                                  " at " +
                                                  item.fromTime}
                                              </span>
                                            </p>
                                          </>
                                        )}
                                      </>
                                      <div className="  mobilerequest block sm:hidden">
                                        <div>
                                          <button
                                            className="text-sm  text-[#0272DA]"
                                            onClick={() =>
                                              handleRequestChange(item)
                                            }
                                          >
                                            Request for Change
                                          </button>
                                        </div>

                                        <div>
                                          {item?.consultationsType === "V" ? (
                                            <img
                                              className="float-right cursor-pointer mt-[-25px]"
                                              width="20px"
                                              src={Profileimg}
                                              onClick={() => videoCall(item)}
                                            />
                                          ) : (
                                            <></>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </>
                            ))}

                          {/* {patientData.length > 3 ? (
                            <>
                              <div class="paginationcontainer">
                                <ReactPaginate
                                  previousLabel={"<"}
                                  nextLabel={">"}
                                  breakLabel={"..."}
                                  pageCount={Math.ceil(
                                    patientData.length / itemsPerPage
                                  )}
                                  marginPagesDisplayed={2}
                                  pageRangeDisplayed={5}
                                  onPageChange={(selected) => {
                                    handlePageChangenew(selected.selected + 1);
                                  }}
                                  containerClassName={"pagination"}
                                  subContainerClassName={"pages pagination"}
                                  activeClassName={"active"}
                                />
                              </div>
                            </>
                          ) : (
                            ""
                          )} */}
                        </TabPanel>

                        <TabPanel value="2">
                          <div>
                            {postAppointment?.length == 0 ? (
                              <div className="w-full text-center align-middle mt-11">
                                <img src={doctoricon} />
                                <p className="text-base font-medium text-[#1A1A1A] mt-2">
                                  No consultation is available right now
                                </p>
                              </div>
                            ) : (
                              ""
                            )}
                            {Array.isArray(postAppointment) &&
                              postAppointment.map((item, index) => (
                                <div
                                  className="flex py-[15px]"
                                  style={{
                                    borderBottom: "1px solid #EAEAF5",
                                  }}
                                >
                                  <div className="w-[100%] flex">
                                    <div className="w-[10%] flex items-center justify-center rounded-lg sm:h-[52px]">
                                      <i
                                        class="fa fa-user-md text-[35px] text-[#2380ec]"
                                        aria-hidden="true"
                                      ></i>
                                    </div>

                                    <div className="w-[100%] pl-[15px] pr-[5px]">
                                      <div className="flex justify-between ">
                                        <h2 className=" sm:text-lg text-base text-[#1A1A1A] mb-0">
                                          {" "}
                                          {item.userSalutation} {item.name}
                                        </h2>
                                      </div>

                                      {index == 0 &&
                                      item?.consultationsType === "V" ? (
                                        <p className="text-base  text-[#1A1A1A] mb-0">
                                          Your call started on{" "}
                                          <span className=" sm:text-base text-sm">
                                            {item.whenAppointment +
                                              " at " +
                                              item.fromTime}
                                          </span>
                                        </p>
                                      ) : (
                                        <>
                                          <p className="sm:text-base text-sm  text-[#1A1A1A] mb-0">
                                            Previous appointment was on{" "}
                                            <span className="   sm:text-base text-sm">
                                              {item.whenAppointment +
                                                " at " +
                                                item.fromTime}
                                            </span>
                                          </p>
                                        </>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              ))}
                          </div>

                          {/* {postAppointment.length > 3 ? (
                            <div class="paginationcontainer">
                              <ReactPaginate
                                previousLabel={"<"}
                                nextLabel={">"}
                                breakLabel={"..."}
                                pageCount={Math.ceil(
                                  postAppointment.length / itemsPerPage
                                )}
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={5}
                                onPageChange={(selected) => {
                                  handlePageChangepostAppointment(
                                    selected.selected + 1
                                  );
                                }}
                                containerClassName={"pagination"}
                                subContainerClassName={"pages pagination"}
                                activeClassName={"active"}
                              />
                            </div>
                          ) : (
                            <></>
                          )} */}
                        </TabPanel>
                      </TabContext>
                    </Box>
                  </div>
                </div>

                {/* prescription div */}

                <div
                  className="sm:w-1/2 sm:h-auto overflow-y-auto rounded-md"
                  style={{ border: " 1px solid #80808073" }}
                >
                  <div className="bottomcontainer2">
                    <div className="flex px-[20px] py-[20px] border-b-2">
                      <div className="w-full">
                        {/* <h2
                          className="pl-[7px] text-[20px]  text-[#0B4881] mb-0"
                          style={{ borderLeft: "5px solid #F03A91" }}
                        >
                          Medicine Schedule
                        </h2> */}
                        <h2
                          className="pl-[7px] text-[20px]  text-[#0B4881] mb-0 font-semibold font-poppins"
                          style={{ borderLeft: "5px solid #00B2DD" }}
                        >
                          Medicine Schedule
                        </h2>
                      </div>
                      {/* <div className="w-1/2 text-right">
                        <a
                          href="https://www.curebay.com/medicine"
                          target="_blank"
                          className="cursor-pointer"
                        >
                          <span
                            className="text-[#004171] rounded-[23px] text-[16px] font-semibold cursor-pointer px-5 py-3 font-poppins"
                            style={{ border: "1px solid #004171" }}
                          >
                            Buy Medicine
                          </span>
                        </a>
                      </div> */}
                    </div>

                    {!medSchedule?.length ? (
                      <div className="pt-11 w-full text-center">
                        <img src={medicineicon}></img>
                        <p className="text-base font-medium text-[#1A1A1A] mt-2">
                          No medicine has been prescribed yet
                        </p>
                      </div>
                    ) : (
                      <div
                        className="pt-[20px] "
                        style={{ margin: "6px 21px 0px 25px" }}
                      >
                        <Card
                          sx={{
                            boxShadow: "rgba(0, 0, 0, 0.1) 0px 2px 28px",
                            width: "100%",
                            paddingX: "2%",
                            borderRadius: "7px",
                            //padding: "8px 12px",
                            marginBottom: "24px",
                          }}
                        >
                          <div className="flex  gap-10 justify-between p-2  border-b-2 border-[#DCDDDE]">
                            <div>
                              <img src={morninmed} />

                              <span className="pdlt-10 ">
                                Morning Medicines
                              </span>
                            </div>
                            <div className="bg-[#66B889] text-white px-[7px] py-[1px] ml-[5px] rounded-sm">
                              {countbeforebreakfast}
                            </div>
                          </div>
                          {medSchedule?.length > 0 &&
                          medSchedule.some(
                            (obj) => obj.dosage?.split("-")[0] === "1"
                          ) ? (
                            <div className="flex flex-col">
                              {medSchedule.map((obj, index) => {
                                const time = obj?.dosage

                                  ?.split("-")

                                  .map(Number);

                                if (time[0] === 1) {
                                  return (
                                    <div className="flex flex-col  p-2 gap-2 border-b-2 border-[#DCDDDE]">
                                      <div className="flex">
                                        <img
                                          src={drug}
                                          className="p-2"
                                          alt="drug"
                                        />
                                        <div
                                          className=" text-base font-medium  "
                                          key={index}
                                        >
                                          {obj?.drugName}
                                        </div>
                                      </div>
                                      <div className="text-[#585858]  pl-8 text-base font-medium  ">
                                        1 Pill:{" "}
                                        {time[0] === 1 &&
                                        obj.instruction === "1"
                                          ? "Before Breakfast"
                                          : time[0] === 1 &&
                                            obj.instruction === "0"
                                          ? "After Breakfast"
                                          : ""}
                                      </div>
                                    </div>
                                  );
                                }

                                return null;
                              })}
                            </div>
                          ) : (
                            <div className="pl-6 pt-3">
                              No medicine has been given for the afternoon
                            </div>
                          )}
                        </Card>

                        <Card
                          sx={{
                            boxShadow: "rgba(0, 0, 0, 0.1) 0px 2px 28px",
                            width: "100%",
                            paddingX: "2%",
                            borderRadius: "7px",
                            //padding: "8px 12px",
                            marginBottom: "24px",
                          }}
                        >
                          <div className="flex  gap-10 justify-between p-2  border-b-2 border-[#DCDDDE]">
                            <div>
                              <img src={afternoon} />

                              <span className="pdlt-10 ">
                                Afternoon Medicines
                              </span>
                            </div>
                            <div className="bg-[#66B889] text-white px-[7px] py-[1px] ml-[5px] rounded-sm">
                              {countbeforelunch}
                            </div>
                          </div>
                          {medSchedule?.length > 0 &&
                          medSchedule.some(
                            (obj) => obj.dosage?.split("-")[1] === "1"
                          ) ? (
                            <div className="flex flex-col">
                              {medSchedule.map((obj, index) => {
                                const time = obj?.dosage

                                  ?.split("-")

                                  .map(Number);

                                if (time[1] === 1) {
                                  return (
                                    <div className="flex flex-col  p-2 gap-2 border-b-2 border-[#DCDDDE]">
                                      <div className="flex">
                                        <img
                                          src={drug}
                                          className="p-2"
                                          alt="drug"
                                        />
                                        <div
                                          className=" text-base font-medium  "
                                          key={index}
                                        >
                                          {obj?.drugName}
                                        </div>
                                      </div>
                                      <div className="text-[#585858]  pl-8 text-base font-medium  ">
                                        1 Pill:{" "}
                                        {time[1] === 1 &&
                                        obj.instruction === "1"
                                          ? "Before Lunch"
                                          : time[1] === 1 &&
                                            obj.instruction === "0"
                                          ? "After Lunch"
                                          : ""}
                                      </div>
                                    </div>
                                  );
                                }

                                return null;
                              })}
                            </div>
                          ) : (
                            <div className="pl-6 pt-3">
                              No medicine has been given for the afternoon
                            </div>
                          )}

                          {/* <div className="flex">
                          <div className="flex flex-col">
                            <div className="br-50 br-4 fc-66B889 h-[15px] w-[15px]"></div>

                            <span className="w-[1px] ht-75 bg-[#1f266d] ml-[7px]"></span>
                          </div>

                          <div className="flex flex-col">
                            <span className="pdlt-10 fc-66B889 ">
                              After Lunch{" "}
                              <span className="bg-[#66B889] text-white px-[7px] py-[1px] ml-[5px] rounded-sm">
                                {countafterlunch}
                              </span>
                            </span>

                            {medSchedule?.length > 0 ? (
                              medSchedule.map((obj, index) => {
                                const time = obj?.dosage

                                  ?.split("-")

                                  .map(Number);

                                if (time[1] === 1 && obj.instruction === "0") {
                                  return (
                                    <li className="fc-18406D text-base ">
                                      {obj?.drugName}
                                    </li>
                                  );
                                }

                                return null;
                              })
                            ) : (
                              <span className="fc-18406D"></span>
                            )}
                          </div>

                        </div> */}
                        </Card>

                        <Card
                          sx={{
                            boxShadow: "rgba(0, 0, 0, 0.1) 0px 2px 28px",
                            width: "100%",
                            paddingX: "2%",
                            borderRadius: "7px",
                            padding: "8px 12px",
                            marginBottom: "24px",
                          }}
                        >
                          <div className="flex  gap-10 justify-between p-2  border-b-2 border-[#DCDDDE]">
                            <div>
                              <img src={night} />

                              <span className="pdlt-10 ">Night Medicines</span>
                            </div>
                            <div className="bg-[#66B889] text-white px-[7px] py-[1px] ml-[5px] rounded-sm">
                              {countbeforedinner}
                            </div>
                          </div>

                          {medSchedule?.length > 0 &&
                          medSchedule.some(
                            (obj) => obj.dosage?.split("-")[2] === "1"
                          ) ? (
                            <div className="flex flex-col">
                              {medSchedule.map((obj, index) => {
                                const time = obj?.dosage

                                  ?.split("-")

                                  .map(Number);

                                if (time[2] === 1) {
                                  return (
                                    <div className="flex flex-col  p-2 gap-2 border-b-2 border-[#DCDDDE]">
                                      <div className="flex">
                                        <img
                                          src={drug}
                                          className="p-2"
                                          alt="drug"
                                        />
                                        <div
                                          className=" text-base font-medium  "
                                          key={index}
                                        >
                                          {obj?.drugName}
                                        </div>
                                      </div>
                                      <div className="text-[#585858]  pl-8 text-base font-medium  ">
                                        1 Pill:{" "}
                                        {time[2] === 1 &&
                                        obj.instruction === "1"
                                          ? "Before Dinner"
                                          : time[2] === 1 &&
                                            obj.instruction === "0"
                                          ? "After Dinner"
                                          : ""}
                                      </div>
                                    </div>
                                  );
                                }

                                return null;
                              })}
                            </div>
                          ) : (
                            <div className="pl-6 pt-3">
                              No medicine has been given for the afternoon
                            </div>
                          )}

                          {/* <div className="flex">
                          <div className="flex flex-col">
                            <div className="br-50 br-6 fc-66B889 h-[15px] w-[15px]"></div>
                          </div>

                          <div className="flex flex-col">
                            <span className="pdlt-10 fc-66B889 ">
                              After Dinner{" "}
                              <span className="bg-[#66B889] text-white px-[7px] py-[1px] ml-[5px] rounded-sm">
                                {countafterdinner}
                              </span>
                            </span>

                            {medSchedule?.length > 0 ? (
                              medSchedule.map((obj, index) => {
                                const time = obj?.dosage

                                  ?.split("-")

                                  .map(Number);

                                if (time[2] === 1 && obj.instruction === "0") {
                                  return (
                                    <li className="fc-18406D text-base ">
                                      {obj?.drugName}
                                    </li>
                                  );
                                }

                                return null;
                              })
                            ) : (
                              <span className="fc-18406D"></span>
                            )}
                          </div>
                        </div> */}
                        </Card>
                      </div>
                    )}
                    {/* 
                    <div className="flex items-center justify-center w-full">
                      <a
                        href="https://www.curebay.com/medicine"
                        target="_blank"
                        className="cursor-pointer"
                      >
                        <button
                          variant="text"
                          className=" bottom-button2  rounded-md  py-2 border text-white bg-[#0272DA] border-[#0272DA] text-blue-500 hover:bg-white hover:text-[#0272DA]  duration-300 text-xs sm:text-base  "
                        >
                          Buy Medicine
                        </button>
                      </a>
                    </div> */}
                  </div>
                </div>
              </div>
              {/* new design end */}
              {/* old code */}

              <div className="md:flex w-full md:mt-8 mt-4 ">
                <div
                  className="w-full md:mr-8 mr-4 overflow-y-auto sm:h-[435px] rounded-md"
                  style={{ border: " 1px solid #80808073" }}
                >
                  <div
                    className=""
                    elevation={1}
                    style={{
                      width: "100%",
                      paddingX: "2%",
                      height: "100%",
                      borderRadius: "5px",
                    }}
                  >
                    <div className="md:mx-2 md:px-2 md:my-4 my-2 border-b-2 md:h-10 ">
                      <div className="w-1/2">
                        <h2
                          className="pl-[7px] text-[20px]  text-[#0B4881] mb-0 font-semibold font-poppins ml-[13px]"
                          style={{ borderLeft: "5px solid #00B2DD" }}
                        >
                          My Medical Plan
                        </h2>
                      </div>
                    </div>
                    <div className="md:flex">
                      <div className="w-full mr-2">
                        {user &&
                          user?.carePlanList?.map((value1, index) => (
                            <Card
                              variant="outlined"
                              elevation={1}
                              sx={{
                                width: "96%%",

                                // height: "6rem",
                                marginY: "2rem",
                                borderRadius: "5px",
                                marginLeft: "16px",
                                marginRight: "8px",
                              }}
                            >
                              <div className="mt-4 flex justify-between items-center sm:pl-0 pl-2">
                                <div className="w-[10%] bg-white flex  rounded-lg sm:h-[52px]">
                                  {value1?.planName ===
                                  "General Health Care" ? (
                                    <img
                                      src={general}
                                      alt="General Health"
                                      className="sm:p-[6px] -mt-5"
                                    />
                                  ) : value1?.planName === "Diabetes Care" ? (
                                    <img
                                      src={diabatic}
                                      alt="Diabetics Care"
                                      className="sm:p-[6px] -mt-5"
                                    />
                                  ) : value1?.planName === "Cardiac Care" ? (
                                    <img
                                      src={hearthealth}
                                      alt="Cardiac Care"
                                      className="sm:p-[6px] -mt-5"
                                    />
                                  ) : value1?.planName === "Ortho Care" ? (
                                    <img
                                      src={ortho}
                                      alt="Ortho Care"
                                      className="sm:p-[6px] -mt-5"
                                    />
                                  ) : value1?.planName ===
                                    "Womens Health Care" ? (
                                    <img
                                      src={Womens}
                                      alt="Womens Health Care"
                                      className="sm:p-[6px] -mt-5"
                                    />
                                  ) : (
                                    <></>
                                  )}
                                </div>
                                <div className="sm:mr-52">
                                  <p className="  text-lg mb-0">
                                    {value1?.planName}
                                  </p>
                                  <p className="text-[16px]   pb-2">
                                    Validity:
                                    <span className=" ">
                                      {value1?.durationMonths} months
                                    </span>{" "}
                                  </p>
                                </div>

                                {/* <Button
                                    variant="text"
                                    onClick={() => navigate(APP_ROUTES.MYPLAN)}
                                    sx={{ color: "#66B889" }}
                                  >
                                    + View Details
                                  </Button> */}
                                <div className="-mt-[18px] sm:mt-0 mr-[8px] rounded-full bg-chathams-blue-200 hover:bg-seegreen  sm:h-11  cursor-pointer sm:mr-6  sm:p-2 sm:block ">
                                  <ChevronRightIcon
                                    sx={{ color: "white" }}
                                    onClick={() => navigate(APP_ROUTES.MYPLAN)}
                                  />
                                </div>
                              </div>
                            </Card>
                          ))}
                        {/* <div className="w-full 3">
                          <div className="flex flex-col m-5 gap-2">
                            <img className="w-[2rem] " src={mark}></img>
                            <p className=" text-xl ">
                              Share your Reviews
                            </p>

                            <button
                              className="  border-chathams-blue-200 border-2 md:px-3 px-1 md:py-2 py-0 rounded-md text-white bg-[#396DDD] md:mx-4 mx-2  md:w-[12rem] "
                              onClick={() => {
                                navigate(APP_ROUTES.MYREVIEW);
                              }}
                            >
                              Give feedback
                            </button>
                          </div>
                        </div> */}
                        <Card
                          variant="outlined"
                          elevation={1}
                          sx={{
                            width: "96%%",

                            // height: "6rem",
                            marginY: "2rem",
                            borderRadius: "5px",
                            marginLeft: "16px",
                            marginRight: "8px",
                            backgroundColor: "#F4EEFF",
                          }}
                        >
                          <div className="w-full">
                            <div className=" pl-3 sm:pl-0">
                              <div className="flex sm:pl-4 sm:pt-2 gap-3 mb-[20px] sm:mb-0">
                                <img
                                  className="w-[2rem] mt-[5px] sm:mt-0"
                                  src={mark}
                                ></img>
                                <p className=" sm:text-[20px] text-[18px]   mb-0 pt-1">
                                  Share your Reviews
                                </p>
                              </div>
                              <div
                                className="sm:flex justify-end sm:mr-7"
                                style={{ margin: "-8px -5px 0px" }}
                              >
                                <span className=" text-[15px] sm:ml-0 ml-[9px] sm:block hidden">
                                  Click on the link to share your review
                                </span>
                                <p className=" text-[15px] sm:ml-0 ml-[9px] sm:hidden block mb-0">
                                  Click on the link to share your review
                                </p>
                                <button
                                  className="  border-chathams-blue-200 border-2 md:px-3 mb-[12px] px-1 md:py-2 py-0 rounded-md text-white bg-[#396DDD] md:mx-4 mx-2  md:w-[10rem] "
                                  onClick={() => {
                                    navigate(APP_ROUTES.MYREVIEW);
                                  }}
                                >
                                  Give feedback
                                </button>
                              </div>
                            </div>
                          </div>
                        </Card>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className="w-full h-[435px] overflow-y-auto sm:mt-0 mt-4 rounded-md"
                  style={{ border: " 1px solid #80808073" }}
                >
                  <div
                    className=""
                    elevation={1}
                    style={{
                      boxShadow: " 0px 10px 13px rgba(0, 0, 0, 0.04)",
                      width: "100%",
                      paddingX: "2%",
                      height: "100%",
                      borderRadius: "5px",
                    }}
                  >
                    <div className="mx-2 px-2 my-4 border-b-2 h-10 ">
                      <div className="w-1/2">
                        <h2
                          className="pl-[7px] text-[20px]  text-[#0B4881] mb-0 font-semibold font-poppins"
                          style={{ borderLeft: "5px solid #00B2DD" }}
                        >
                          Health Tips
                        </h2>
                      </div>
                    </div>
                    <div>
                      <p className="text-[16px]   pl-4">
                        Check out some videos related to Health Tips
                      </p>
                    </div>
                    <div className="flex my-3 w-full gap-5 flex-col">
                      {videos?.length &&
                        videos?.map((obj, index) => {
                          return (
                            <iframe
                              allowFullScreen
                              src={obj?.video_url}
                              // src={'https://www.youtube.com/embed/3Us3Wy2AMg0'}
                            ></iframe>
                          );
                        })}
                    </div>
                  </div>
                </div>
              </div>

              <div className="md:flex w-full md:mt-8 mt-4 ">
                <div
                  className="w-full md:mr-8 mr-4 rounded-md"
                  style={{ border: " 1px solid #80808073" }}
                >
                  <Card
                    sx={{
                      boxShadow: " 0px 10px 13px rgba(0, 0, 0, 0.04)",
                      width: "100%",
                      paddingX: "2%",
                      height: "100%",
                      borderRadius: "5px",
                    }}
                  >
                    <div className="md:mx-2 md:px-2 md:my-4 my-2 border-b-2 xl:h-12 xl:flex gap-20 pt-1">
                      <h2
                        className="pl-[7px] text-[20px]  text-[#0B4881] mb-0 font-semibold font-poppins"
                        style={{ borderLeft: "5px solid #00B2DD" }}
                      >
                        Exercise
                      </h2>
                      <p className="md:text-[16px] text-sm   pt-1">
                        Health instruction Exercise given by Doctor
                      </p>
                    </div>
                    <div className="grid lg:grid-cols-2 justify-center items-center gap-y-6 md:pb-6 pb-1">
                      {excercise?.map((obj, index) => {
                        return (
                          <div className="flex flex-col items-center">
                            <img
                              className={`${
                                index == 0
                                  ? " sm:w-[2.7rem] w-[2rem] "
                                  : " sm:w-auto w-[3.7rem] "
                              }`}
                              src={obj.image_url}
                            />
                            <h1 className="  md:text-[18px] text-sm">
                              {obj.title}
                            </h1>
                            <p className="  md:text-[15px] text-sm">
                              {obj.short_description}
                            </p>
                          </div>
                        );
                      })}
                    </div>
                  </Card>
                </div>
                <div
                  className="w-full py:2 rounded-md "
                  style={{ border: " 1px solid #80808073" }}
                >
                  <Card
                    sx={{
                      boxShadow: " 0px 10px 13px rgba(0, 0, 0, 0.04)",
                      width: "100%",
                      paddingX: "4%",
                      height: "100%",
                      borderRadius: "5px",
                    }}
                    className="mt-[20px] sm:mt-0"
                  >
                    <div className="md:my-4 my-2 border-b-2 md:h-10">
                      <h2
                        className="pl-[7px] text-[20px]  text-[#0B4881] mb-0 font-semibold font-poppins"
                        style={{ borderLeft: "5px solid #00B2DD" }}
                      >
                        Diet Instructions
                      </h2>
                    </div>

                    <div className="flex my-3 w-full gap-1 flex-col">
                      <h1 className="text-[14px]   pt-2">Given by doctor</h1>
                      <h2 className="text-[#0B4881]   md:text-[20px] text-16px mb-0">
                        4 Weeks Plan
                      </h2>
                      <div className="flex flex-col gap-4 mt-4">
                        {diet?.map((obj, index) => {
                          return (
                            <div className="flex justify-between items-center">
                              <div className="w-[30%] sm:w-auto">
                                <p className=" mb-0 ">{obj.mealTime}</p>
                                <p className="  md:text-[18px] text-sm mb-0">
                                  {obj.mealName}
                                </p>
                              </div>
                              <div
                                className={`${
                                  index == 0
                                    ? " bg-[#FDF5F1] "
                                    : index == 1
                                    ? "  bg-[#F0F5FF]  "
                                    : " bg-[#DDFBFF] "
                                } flex items-center sm:w-[25rem] w-[70%] gap-2 px-3 md:h-9 rounded-md  `}
                              >
                                <img width="25px" src={obj.icon_url} />
                                <p
                                  className={`${
                                    index == 0
                                      ? " text-[#FF6D4F] "
                                      : index == 1
                                      ? "  text-[#5887EF]  "
                                      : " text-[#349FAD] "
                                  }  md:text-16px text-sm mb-0`}
                                >
                                  {obj.mealDetail}
                                </p>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </Card>
                </div>
              </div>
            </div>
          </div>
        </div>
        {url && !close && (
          <Dialog
            open={url}
            fullWidth={true}
            sx={{
              "& .MuiDialog-container": {
                "& .MuiPaper-root": { width: 10 / 12, maxWidth: "100%" },
              },
            }}
          >
            <DialogTitle
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <p className="p-2 text-[40px]  text-chathams-blue-500  ">
                Video Consultation
              </p>
              <IconButton onClick={handleClose} aria-label="Close">
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              <div id="videoframe">
                <div
                  dangerouslySetInnerHTML={{
                    __html: `<iframe id="ccc" height="465" style="width: 100%;"  src=${url} allow="camera;microphone"></iframe>`,
                  }}
                ></div>
              </div>
            </DialogContent>
            {/* <DialogActions>
                        <Button onClick={() => setUrl('')}>Close</Button>

                    </DialogActions> */}
          </Dialog>
        )}
      </div>
      <Dialog open={confirmDialogOpen} onClose={handleCancelChange}>
        <DialogTitle>Change Effective Start Date</DialogTitle>
        <DialogContent>
          <p>Are you sure you want to change the effective start date?</p>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelChange}>Cancel</Button>
          <Button
            onClick={handleConfirmChange}
            variant="contained"
            color="primary"
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      <Modal open={open} onClose={handleClosenew}>
        <div className="md:fixed h-full inset-0 md:flex items-center justify-center">
          <div className="h-auto md:w-auto bg-white p-4  relative ">
            <div className="md:w-full mx-5 pr-[30px]">
              <div className="w-full flex justify-end ">
                <IconButton onClick={handleClosenew} aria-label="Close">
                  <CloseIcon />
                </IconButton>
              </div>
              <div className="md:mt-4 mt-0">
                <p className="text-[24px] font-semibold text-center sm:text-start text-[#004171]">
                  Do you want to register with titan for {selectedName}
                </p>
                <li>
                  If <span className="font-semibold">YES</span> please click to{" "}
                  <span className="font-semibold">PROCCED</span>.
                </li>
                <li>
                  If <span className="font-semibold">NO</span> please select
                  another member from member profile.
                </li>

                <div className="float-right mt-[40px]">
                  <button
                    className="bg-[#004171] text-white  text-sm sm:text-base  rounded-md  py-[6px] px-[42px]"
                    onClick={() => handleAuthorization()}
                  >
                    PROCCED
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      <Dialog open={consentOpen} onClose={handleCancelChangenew}>
        <DialogContent>
          <p>Please read and accept the updated consent form</p>
        </DialogContent>
        <DialogActions>
          <Button onClick={handlenewChange} variant="contained" color="primary">
            Read & Accept
          </Button>
        </DialogActions>
      </Dialog>

      {/* <Dialog
        open={consentopennew}
        onClose={handleCloseconsent}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
        maxWidth={"md"}
      >
        <DialogTitle id="alert-dialog-title">
          <p className="w-full text-center"> Consent Form</p>
        </DialogTitle>
        <DialogContent
          sx={{
            borderWidth: 2,
            borderColor: "divider",
            marginX: "3rem",
            padding: "3rem",
          }}
        >
          <Box
            sx={{
              width: "100%",
              height: "18rem",
              typography: "body1",
              margin: "0rem",
            }}
          >
            <TabContext value={value}>
              <Box sx={{ borderBottom: 1, borderColor: "#000000" }}>
                <TabList
                  onChange={handleChangenew}
                  sx={{
                    "& .Mui-selected": {
                      color: "#66B889 !important",
                    },
                  }}
                  TabIndicatorProps={{
                    style: { backgroundColor: "#66B889" },
                  }}
                >
                  <Tab
                    label={consentdata?.attributes?.SecondComp?.title1}
                    value="1"
                  />
                  <Tab
                    label={consentdata?.attributes?.SecondComp?.title2}
                    value="2"
                  />
                </TabList>
              </Box>

              <TabPanel value="1">
                <div className="text-justify">
                  {consentdata?.attributes?.SecondComp?.description1}
                </div>
              </TabPanel>
              <TabPanel value="2">
                <div className="text-justify">
                  {consentdata?.attributes?.SecondComp?.description2}
                </div>
              </TabPanel>
            </TabContext>
          </Box>
        </DialogContent>
        <DialogActions>
          <div className="mx-10 my-10">
            <button
              className="text-chathams-blue-200 mr-4 border-chathams-blue-200 border-2 px-3 py-2 rounded-md hover:text-white hover:bg-chathams-blue-200 font-semibold w-28 "
              style={{ border: "1px solid #396DDD" }}
              onClick={handleAgree}
            >
              Accept
            </button>
            <button
              className="text-chathams-blue-200 border-chathams-blue-200 border-2 px-3 py-2 rounded-md hover:text-white hover:bg-chathams-blue-200 font-semibold w-28"
              style={{ border: "1px solid #396DDD" }}
              onClick={handleDisagree}
            >
              Decline
            </button>
          </div>
        </DialogActions>
      </Dialog> */}

      <Primereactdialog
        modal
        showHeader={false}
        visible={consentopennew}
        className="w-full sm:w-2/3 h-screen"
        // onHide={() => setIsVisible(!isVisible)}
      >
        <div style={{ minHeight: "max-content" }}>
          <h1 className="text-xl text-black font-semibold">Consent</h1>
          <div>
            <PrimereacttabView>
              <Primereacttabpanel header="Terms and condition">
                <iframe
                  className="border rounded cursor-pointer h-96 w-full"
                  src={`${process.env.REACT_APP_IMAGE_URL}${consentInfo.tcPdf}`}
                />
              </Primereacttabpanel>
              <Primereacttabpanel header="Privacy Policy">
                <iframe
                  className="border rounded cursor-pointer h-96 w-full"
                  src={`${process.env.REACT_APP_IMAGE_URL}${consentInfo.privacyPdf}`}
                />
              </Primereacttabpanel>
            </PrimereacttabView>
          </div>
          <div className="flex justify-end p-4">
            <button
              onClick={handleAgree}
              className=" text-white  text-sm font-bold px-2 py-2 rounded cursor-pointer lg:px-18 mr-4"
              style={{ backgroundColor: "#66B889" }}
            >
              Accept
            </button>
            <button
              onClick={handleDisagree}
              className=" text-white  text-sm font-bold px-2 py-2 rounded cursor-pointer lg:px-18"
              style={{ backgroundColor: "red" }}
            >
              Decline
            </button>
          </div>
        </div>
      </Primereactdialog>

      {screen > 500 ? (
        <Footer i={1} />
      ) : (
        <>
          <FooterMobileVersion />
          <MobileFooter />
        </>
      )}
      {isROCOpen && selectedItem ? (
        <ROC open={isROCOpen} props={selectedItem} onClose={handleCloseROC} />
      ) : null}
    </>
  );
};

export default Index;
