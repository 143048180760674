import loginservice from "../../services/loginservice";

export const fetchOtp = async (payload) => {
  const res = await loginservice.generateOtp(payload);
  // const res = await loginservice.generateOtp(JSON.stringify(payload));
  return res;
};
export const fetchEmail = async (payload) => {
  const res = await loginservice.pswdCheck(payload);
  // const res = await loginservice.pswdCheck(JSON.stringify(payload));
  return res;
};

export const signUp = async (payload) => {
  const res = await loginservice.signup(JSON.stringify(payload));
  return res;
};

export const landingpage = async (payload) => {
  const res = await loginservice.landingpage(JSON.stringify(payload));
  return res;
};

export const patientSave = async (payload) => {
  const res = await loginservice.patientsave(JSON.stringify(payload));
  return res;
};

export const updateCart = async (payload) => {
  const res = await loginservice.updateCart(JSON.stringify(payload));
  return res;
};
export const fetchConsent = async () => {
  const res = await loginservice.getConsent();
  return res;
};
export const consentData = async (payload) => {
  const res = await loginservice.consent(JSON.stringify(payload));
  return res;
};

export const isSelfLogIn = async (payload) => {
  const res = await loginservice.isSelfLogIn(JSON.stringify(payload));
  return res;
};

export const selfCareGiverLogin = async (payload) => {
  const res = await loginservice.selfCareGiverLogin(JSON.stringify(payload));
  return res;
};
export const saveVitalsdata = async (payload) => {
  const res = await loginservice.saveVitalsdata(JSON.stringify(payload));
  return res;
};
