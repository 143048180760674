import { AUTH } from '../constants/authconstants';
const CryptoJS = require("crypto-js");
const SecureStorage = require('secure-web-storage')
const SECRET_KEY = 'secureMyData';

export var secureStorage = new SecureStorage(localStorage, {
    hash: function hash(key) {
        key = CryptoJS.SHA256(key, SECRET_KEY);
        return key.toString();
    },
    encrypt: function encrypt(data) {
        data = CryptoJS.AES.encrypt(data, SECRET_KEY);
        data = data.toString();
        return data;
    },
    decrypt: function decrypt(data) {
        data = CryptoJS.AES.decrypt(data, SECRET_KEY);
        data = data.toString(CryptoJS.enc.Utf8);
        return data;
    }
});

const initialState = {
    userData: {},
    token: secureStorage.getItem('token') ? secureStorage.getItem('token') : null,
    
};

const AuthReducer = (state = initialState, action) => {
    const { type, payload } = action;
    const localStorageData = localStorage.getItem('loginObj');
    const newUserData = localStorageData || payload;

    switch (type) {
        case AUTH.SETOKEN:
            return {
                ...state,
                token: payload.access_token,
                isLoading: true,
                isError: false
            };
        case AUTH.USERDATA:
            return {
                ...state,
                userData: newUserData
            };
        default:
            return state;
    }
}

export function encryptData(data) {
    const key = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_ENCRYPTION_KEY);
    const iv = CryptoJS.enc.Utf8.parse("encryptionIntVec");
   
    if (typeof data !== "string") {
      data = JSON.stringify(data);
    }
    const cipherText = CryptoJS.AES.encrypt(data, key, {
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    });
    return { data:cipherText.toString() };
  }
  
  export function decryptData(cipherText) {
    let secretkey = process.env.REACT_APP_ENCRYPTION_KEY;
    let initVector = 'encryptionIntVec';
    const key = CryptoJS.enc.Utf8.parse(secretkey);
    const iv = CryptoJS.enc.Utf8.parse(initVector);
   
    const bytes = CryptoJS.AES.decrypt(cipherText, key, {
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    });
    let originalText = bytes.toString(CryptoJS.enc.Utf8);
    try {
      originalText = JSON.parse(originalText);
    } catch (error) {}
    return originalText;
  }


export default AuthReducer;