import { encryptData } from "../reducer/authreducer";
import http from "./httpcommon";
class AuthDataService {

    // callToken = async () => {
    //     var data = new FormData();
    //     // @ts-ignore
    //     data.append('username', process.env.REACT_APP_USERNAME);
    //     // @ts-ignore
    //     data.append('password', process.env.REACT_APP_PASSWORD);
    //     // @ts-ignore
    //     data.append('grant_type', process.env.REACT_APP_GRANT_TYPE);
    //     // @ts-ignore
    //     return http.post(process.env.REACT_APP_MEDICARE_BASEURL + process.env.REACT_APP_TOKEN, data, {
    //         // @ts-ignore
    //         auth: { username: process.env.REACT_APP_AUTH_USERNAME, password: process.env.REACT_APP_AUTH_PASSWORD }
    //     })
    // }
    callToken = async () => {
        let jsonData = {
            userCode: process.env.REACT_APP_USER_CODE,
            passWord: process.env.REACT_APP_USER_PASSWORD,
            channel: 'M'
          };
        
          let encryptedPayload = encryptData(jsonData); // Encrypt the payload
          // @ts-ignore
          return http.post(process.env.REACT_APP_MEDICARE_BASEURL + process.env.REACT_APP_TOKEN, encryptedPayload)
    }
}

export default new AuthDataService();