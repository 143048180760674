import React, { useEffect, useState } from "react";
import logoimg from "../../webflowimages/curebay_logo.png"
import DSC0046review from "../../webflowimages/_DSC0046review-2.jpg"
import Footer from '../../components/webflow/footer';
import { useSelector } from "react-redux";
import 'aos/dist/aos.css';
import Aos from "aos";
import MobileFooter from "./../homepage/mobileFooter";
import FooterMobileVersion from "../homepage/FooterMobileVersion";



const Privacypolicy = () => {

    const [screen, setscreen] = useState(window.innerWidth);
    const homePageContent = useSelector(
        (state) => state.HomePageReducer.homePageContent

    );
    useEffect(() => {
        Aos.init({ duration: 2000 })
    }, [])


    return (
        <>
            <div className="page-wrapper">
                <section className="section" >
                    <h1 className="display-2 m-0" data-aos="fade-right">Privacy Policy</h1>
                    <div className="container-default w-container" data-aos="slide-up">
                        <div className="rich-text-block-3 w-richtext">


                            <div className="container-default w-container">
                                <div className="rich-text-block-3 w-richtext">
                                    <p>
                                        {/* <strong>Privacy Policy</strong> */}
                                        <br />
                                        Effect From: 13 th October 2023
                                        <br />
                                        These terms of use read with the Privacy Policy (the “Privacy Policy”) are an electronic record under 
                                        <br />
                                        the Information Technology Act, 2000 and the rules made thereunder as
                                        amended and is enforceable
                                        <br />
                                        against You (as hereinafter defined) under law by way of your acceptance
                                        hereof.
                                        <br />
                                        The domain name https://curebaytotalcare.com and applications for mobile,
                                        tablet and other smart
                                        <br />
                                        devices and application program interfaces, if any (collectively referred
                                        to as the “Website”),
                                        <br />
                                        operated by CureBay Technologies Private Limited, a private limited
                                        company duly incorporated
                                        <br />
                                        under the provisions of the Companies Act, 2013 (hereinafter referred to
                                        as “CureBay” or “CureBay
                                        <br />
                                        Totalcare or ‘Curebay” or “We” or “Our” or “Us” or “Company”).
                                        <br />
                                        Your access or use of the Website, transaction on the Website, are
                                        governed by the following terms
                                        <br />
                                        and conditions along with any amendments / modifications made by CureBay
                                        at its sole discretion and
                                        <br />
                                        posted on the Website (hereinafter referred to as the “Terms of Use”),
                                        including by way of imposing
                                        <br />
                                        an additional charge for access to, or use of, the Service(s) (as
                                        hereinafter defined). These Terms of
                                        <br />
                                        Use constitutes a legal and binding contract between You on one part and
                                        CureBay on the other part.
                                        <br />
                                        This Website is an aggregation platform that (i) connects Users to the
                                        healthcare providers and other
                                        <br />
                                        key healthcare ecosystem players, (ii) facilitates diagnostic services
                                        being offered by various third
                                        <br />
                                        party diagnostic centers, and (iii) facilitates online medical consultancy
                                        services/ second opinion
                                        <br />
                                        being offered by third party independent doctors (collectively the
                                        “Healthcare Providers”) to
                                        <br />
                                        enable You to avail their services provided by the Healthcare Providers
                                        under the terms agreed
                                        <br />
                                        between You and the Healthcare Providers (the “Services”). Further, the
                                        Website also serves as an
                                        <br />
                                        information platform providing health and wellness related information
                                        (“Information Services”) to
                                        <br />
                                        the Users accessing the Website. It is clarified that CureBay and its
                                        affiliates do not provide any
                                        <br />
                                        Services which is the domain of the Healthcare Provider. Notwithstanding
                                        anything to the contrary
                                        <br />
                                        contained herein, the Healthcare Providers alone shall be liable for their
                                        dealings and interaction with
                                        <br />
                                        the Users who avail the services of the Healthcare Providers contacted or
                                        managed through the
                                        <br />
                                        Website and CureBay is acting only as an aggregator and shall have no
                                        liability or responsibility in
                                        <br />
                                        this regard.
                                        <br />
                                        The arrangement between the User and CureBay shall be governed in
                                        accordance with the Terms of
                                        <br />
                                        Use and Privacy Policy. For the purpose of these Terms of Use, wherever
                                        the context so requires
                                        <br />
                                        “You” or “Your” or “Users” shall mean any natural person (which expression
                                        shall include legal
                                        <br />
                                        heirs, representative, executors, administrators, successors and permitted
                                        assigns) who has agreed to
                                        <br />
                                        become a user on the Website by registering on the Website as a registered
                                        User using the computer
                                        <br />
                                        systems, or just visiting and browsing the Website as a non-registered
                                        User, or a patient, his or her
                                        <br />
                                        representative searching for Services through the Website/platform.
                                        <br />
                                        The User and CureBay shall hereinafter be referred to individually, as
                                        “Party” and collectively, as
                                        <br />
                                        “Parties”.
                                        <br />
                                        By accessing, browsing or in any way transacting on the Website, or
                                        availing any of the Services, You
                                        <br />
                                        signify Your agreement to be bound by these Terms of Use. For transaction
                                        via our Website, by
                                        <br />
                                        clicking on the “Accept” or “Agree” button you agree to the Terms of Use
                                        that will bind you. If you
                                        <br />
                                        do not agree to these terms and conditions you must cease to transact on
                                        the Website. Further, by
                                        <br />
                                        impliedly or expressly accepting these Terms of Use, You also accept and
                                        agree to be bound by Our
                                        <br />
                                        policies, including the Privacy Policy and such other rules, guidelines,
                                        policies, terms and conditions
                                        <br />
                                        as are relevant under the applicable law(s) in India and other
                                        jurisdictions for the purposes of
                                        <br />
                                        accessing, browsing or transacting on the Website, and such rules,
                                        guidelines, policies, terms and
                                        <br />
                                        conditions shall be deemed to be incorporated into, and considered as part
                                        and parcel of these Terms
                                    </p>
                                    <p>
                                        of Use. Furthermore, we reserve the right to change, modify, add or delete
                                        portions of the terms of
                                        <br />
                                        this privacy policy, at our sole discretion, at any time.
                                        <br />
                                        This Privacy Policy is an electronic record in the form of an electronic
                                        contract formed under the
                                        <br />
                                        Information Technology Act, 2000 and the relevant rules made thereunder,
                                        and any other applicable
                                        <br />
                                        statutes, as amended from time to time. This Privacy Policy does not
                                        require any physical, electronic
                                        <br />
                                        or digital signature(s).
                                    </p>
                                    <h3>1. WHY THIS PRIVACY POLICY?</h3>
                                    <p>
                                        ‍<br />
                                        This Privacy Policy is published in compliance with, inter alia:
                                        <br />
                                        1. Information Technology Act, 2000;
                                        <br />
                                        2. Information Technology (Reasonable Security Practices and Procedures
                                        and Sensitive
                                        <br />
                                        Personal Information) Rules, 2011 (the “SPI Rules”);
                                        <br />
                                        3. Information Technology (Intermediaries Guidelines) Rules, 2011.
                                    </p>
                                    <p>
                                        ‍<br />
                                        This Privacy Policy states the following:
                                        <br />
                                        1. The type of information collected from the Users, including Personal
                                        Information (as defined
                                        <br />
                                        in paragraph 2 below) and Sensitive Personal Data or Information (as
                                        defined in paragraph 2<br />
                                        below) relating to an individual;
                                        <br />
                                        2. The purpose, means and modes of collection, usage, processing,
                                        retention and destruction of
                                        <br />
                                        such information; and
                                        <br />
                                        3. How and to whom CureBay will disclose such information.
                                    </p>
                                    <h3>
                                        ‍<br />
                                        2. COLLECTION OF PERSONAL INFORMATION
                                    </h3>
                                    <h5>
                                        ‍<br />
                                        2.1 Personal Data
                                    </h5>
                                    <p>
                                        ‍<br />
                                        While using our service, we may ask you to provide certain personally
                                        identifiable information that
                                        <br />
                                        can be used to contact or identify you (“Personal Data”). This may
                                        include, but is not limited to:
                                        <br />
                                        Email address, First name and last name, Phone number, Address, State,
                                        Province, PIN/Zip
                                        <br />
                                        code, City, Gender and other demographics, Date of birth and age, Location
                                        data and Cookies
                                        <br />
                                        and usage data
                                        <br />
                                        Such Personal Data may be collected in various ways including during the
                                        course of your: (i)
                                        <br />
                                        registration as a user on the Website; (ii) availing services offered on
                                        the Website; (iii) granting
                                        <br />
                                        permission to share credentials of your online accounts maintained with
                                        third parties.
                                    </p>
                                    <h5>
                                        ‍<br />
                                        2.2 Cookies and Usage Data
                                    </h5>
                                    <p>
                                        ‍<br />
                                        We may use your Personal Data to contact you with newsletters, marketing
                                        or promotional materials,
                                        <br />
                                        and other information, only if you have explicitly consented to receive
                                        such communications. You can
                                        <br />
                                        opt-out of receiving these communications by following the unsubscribe
                                        link or instructions provided
                                        <br />
                                        in any email we send.
                                        <br />
                                        To access certain areas of the App, you may need to complete a
                                        registration form. During registration,
                                        <br />
                                        you will be required to provide information such as your name, age, email
                                        address, preferred
                                        <br />
                                        username, password, and preferred subscriptions. Please note that the
                                        username you choose will be
                                        <br />
                                        publicly visible on the App.
                                    </p>
                                    <p>
                                        To store a medication list, you need to create a user account. You have
                                        control over who can access or
                                        <br />
                                        edit your medication list. Your account will only be accessible to
                                        authorized support staff or service
                                        <br />
                                        providers who require access to perform tasks on our behalf. These service
                                        providers are obligated not
                                        <br />
                                        to disclose or use your Personal Data for any other purpose.
                                        <br />
                                        If you choose to create a public profile and add Personal Data about
                                        yourself or others, this
                                        <br />
                                        information will be publicly accessible. If you wish to request the
                                        removal of your information from
                                        <br />
                                        our directory, please contact us using the contact details provided at the
                                        bottom of this Policy.
                                        <br />
                                        If you choose to participate in any community-oriented parts of our App,
                                        such as forums, blogs, chat
                                        <br />
                                        rooms, Q&amp;amp;A, reviews, or comments, please be aware that any
                                        Personal Data you submit is public and
                                        <br />
                                        can be read, collected, and used by others. It may also appear in search
                                        engines. To request the
                                        <br />
                                        removal of your Personal Data from our blog or community forum, please
                                        contact us using the contact
                                        <br />
                                        details provided at the bottom of this Policy. In some cases, we may not
                                        be able to remove your
                                        <br />
                                        Personal Data, and we will inform you if we are unable to do so and
                                        provide the reason.
                                    </p>
                                    <h5>
                                        ‍<br />
                                        2.3 Sensitive Personal Data
                                    </h5>
                                    <p>
                                        ‍<br />
                                        If we collect health data or other sensitive personal data, we will ask
                                        for your explicit consent to
                                        <br />
                                        process the data. You can use our contact form to withdraw your consent at
                                        any time. In such an
                                        <br />
                                        event, the Company reserves the right to not allow you further usage of
                                        the Website, without any
                                        <br />
                                        obligations or liability, whatsoever, whether in contract, tort, law,
                                        equity or otherwise, in this regard.
                                    </p>
                                    <h5>
                                        ‍<br />
                                        2.4 Usage Data
                                    </h5>
                                    <p>
                                        ‍<br />
                                        We may collect information that your browser or mobile device sends
                                        whenever you use our App
                                        <br />
                                        (“<strong>Usage Data</strong>”). This may include but not limited to:
                                        <br />
                                        Internet Protocol (IP) address
                                        <br />
                                        Browser type and version
                                        <br />
                                        Pages visited within the App
                                        <br />
                                        Time and date of visits
                                        <br />
                                        Time spent on those pages
                                        <br />
                                        Search terms
                                        <br />
                                        Page content
                                        <br />
                                        Unique device identifiers
                                        <br />
                                        Other diagnostic data
                                        <br />
                                        When you access our App through a mobile device, the Usage Data may
                                        include information such as
                                        <br />
                                        the type of mobile device you use, your mobile device&amp;#39;s unique ID,
                                        the IP address of your mobile
                                        <br />
                                        device, your mobile operating system, the type of mobile Internet browser
                                        you use, and other
                                        <br />
                                        diagnostic data.
                                    </p>
                                    <h5>
                                        ‍<br />
                                        2.5 Tracking and Cookies Data
                                    </h5>
                                    <p>
                                        ‍<br />
                                        We and our partners use cookies or similar technologies to analyze trends,
                                        administer the App, track
                                        <br />
                                        users&amp;#39; movements around the App, and gather demographic
                                        information about our user base as a<br />
                                        whole. You can control the use of cookies through your browser settings
                                        and other tools. However,
                                        <br />
                                        please note that disabling cookies may affect your ability to access and
                                        use certain features of the
                                        <br />
                                        App. Cookies are small files stored on your device (computer or mobile
                                        device). They may include an
                                        <br />
                                        anonymous unique identifier. Tracking technologies also used are beacons,
                                        tags, and scripts to collect
                                        <br />
                                        and track information and to improve and analyze our service.
                                    </p>
                                    <h3>
                                        ‍<br />
                                        3. COLLECTION, USE, AND DISCLOSURE OF MEDICAL DATA
                                    </h3>
                                    <h5>‍</h5>
                                    <h5>3.1 Collection of Medical Data:</h5>
                                    <p>
                                        ‍<br />
                                        a. We collect user medical data for the purpose of providing healthcare
                                        services, personalized care,
                                        <br />
                                        and improving the functionality of our platform.
                                        <br />
                                        b. The data collected may include personal information, medical history,
                                        treatment information, test
                                        <br />
                                        results, prescriptions, and other relevant medical information.
                                        <br />
                                        c. We only collect medical data that is necessary and relevant to the
                                        provision of healthcare services.
                                        <br />
                                        3.2 Use of Medical Data:
                                        <br />
                                        a. We use user medical data to provide healthcare services, including
                                        diagnosis, treatment, and
                                        <br />
                                        ongoing care.
                                        <br />
                                        b. The data may be used for internal analysis, research, quality
                                        improvement, and development of new
                                        <br />
                                        healthcare products and services.
                                        <br />
                                        c. We may aggregate and de-identify medical data to conduct statistical
                                        analysis, research, or for other
                                        <br />
                                        lawful purposes.
                                    </p>
                                    <h5>
                                        ‍<br />
                                        3.3 Disclosure of Medical Data:
                                    </h5>
                                    <p>
                                        ‍<br />
                                        a. We do not disclose user medical data to third parties without the
                                        user&amp;#39;s explicit consent,
                                        <br />
                                        except as required by law or as necessary for the provision of healthcare
                                        services.
                                        <br />
                                        b. In certain cases, we may share medical data with healthcare providers,
                                        specialists, or other
                                        <br />
                                        entities involved in the user&amp;#39;s care, to ensure continuity of care
                                        and appropriate treatment.
                                        <br />
                                        c. We may share aggregated and de-identified data for research, analytics,
                                        or other lawful
                                        <br />
                                        purposes.
                                    </p>
                                    <h3>
                                        ‍<br />
                                        4. DATA SECURITY
                                    </h3>
                                    <p>
                                        ‍<br />
                                        a. We implement robust security measures to protect user medical data
                                        against unauthorized
                                        <br />
                                        access, disclosure, alteration, or destruction.
                                        <br />
                                        b. These measures include encryption, access controls, regular security
                                        assessments, staff
                                        <br />
                                        training, and compliance with industry best practices.
                                        <br />
                                        c. We regularly review and update our security practices to address
                                        emerging threats and
                                        <br />
                                        ensure the ongoing protection of user data.
                                        <br />
                                        4.1 Indian Data Protection Laws:
                                        <br />
                                        a. We comply with the applicable data protection laws and regulations in
                                        India to safeguard
                                        <br />
                                        your personal information.
                                        <br />
                                        b. We take reasonable steps to ensure that your data is treated securely
                                        and in accordance with
                                        <br />
                                        this Privacy Policy.
                                        <br />
                                        c. We ensure that any transfers of data outside India comply with the
                                        requirements under
                                        <br />
                                        Indian law, including obtaining your explicit consent if necessary.
                                    </p>
                                    <h3>
                                        ‍<br />
                                        5. USER RIGHTS
                                    </h3>
                                    <p>‍</p>
                                    <p>
                                        a. Users have the right to access, review, and correct their medical
                                        information, as permitted
                                        <br />
                                        by Indian law.
                                        <br />
                                        b. Users can request the deletion or restriction of their medical data,
                                        subject to legal and
                                        <br />
                                        regulatory requirements.
                                        <br />
                                        c. We provide mechanisms for users to exercise their rights and handle
                                        such requests
                                        <br />
                                        promptly and transparently.
                                        <br />
                                        Third-Party Providers:
                                        <br />
                                        a. In some cases, we may engage third-party service providers or business
                                        associates to assist in
                                        <br />
                                        processing or handling user medical data.
                                        <br />
                                        b. These entities are contractually bound to maintain the privacy and
                                        security of the data and comply
                                        <br />
                                        with applicable laws and regulations.
                                    </p>
                                    <h3>
                                        <br />
                                        6. DATA RETENTION
                                    </h3>
                                    <p>
                                        ‍<br />
                                        a. We retain user medical data for the duration required by law,
                                        regulations, or as necessary for the
                                        <br />
                                        provision of healthcare services.
                                        <br />
                                        b. When data is no longer needed, we securely dispose of or de-identify it
                                        to ensure continued privacy
                                        <br />
                                        and protection.
                                    </p>
                                    <h3>
                                        ‍<br />
                                        7. USE OF DATA
                                    </h3>
                                    <p>
                                        ‍<br />
                                        CureBay Totalcare uses the collected data for various purposes, including:
                                        <br />
                                        a. To provide and maintain the App
                                        <br />
                                        b. To notify you about changes to our App
                                        <br />
                                        c. To allow you to participate in interactive features of our App when you
                                        choose to do so
                                        <br />
                                        d. To provide customer support
                                        <br />
                                        e. To gather analysis or valuable information to improve our App
                                        <br />
                                        f. To monitor the usage of our App
                                        <br />
                                        g. To detect, prevent, and address technical issues
                                        <br />
                                        h. To provide you with news, special offers, and general information about
                                        other goods,
                                        <br />
                                        services, and events we offer that are similar to those you have already
                                        purchased or inquired
                                        <br />
                                        about, unless you have opted not to receive such information
                                        <br />
                                        Transfer of Data:
                                        <br />
                                        Your information, including Personal Data, may be transferred to — and
                                        maintained on — computers
                                        <br />
                                        located outside of India, where data protection laws may differ from those
                                        of your jurisdiction.
                                        <br />
                                        If you are located outside India and choose to provide information to us,
                                        please note that we transfer
                                        <br />
                                        the data, including Personal Data, to India and process it there.
                                        <br />
                                        Your consent to this Privacy Policy, followed by your submission of such
                                        information, represents
                                        <br />
                                        your agreement to that transfer.
                                    </p>
                                    <p>
                                        CureBay Totalcare will take all the steps reasonably necessary to ensure
                                        that your data is treated
                                        <br />
                                        securely and in accordance with this Privacy Policy, and no transfer of
                                        your Personal Data will take
                                        <br />
                                        place to an organization or a country unless there are adequate controls
                                        in place, including the security
                                        <br />
                                        of your data and other personal information.
                                    </p>
                                    <h3>
                                        ‍<br />
                                        8. DISCLOSURE OF DATA
                                    </h3>
                                    <p>
                                        ‍<br />
                                        Legal Requirements
                                        <br />
                                        CureBay Totalcare may disclose your Personal Data in good faith belief that
                                        such action is necessary
                                        <br />
                                        to:
                                        <br />
                                        a. Comply with a legal obligation or an order of any governmental
                                        authority
                                        <br />
                                        b. Protect and defend the rights or property of CureBay Totalcare
                                        <br />
                                        c. Prevent or investigate possible wrongdoing in connection with the App
                                        <br />
                                        d. Protect the personal safety of users of the App or the public
                                        <br />
                                        e. Protect against legal liability
                                        <br />
                                        In the event of CureBay being party to a merger, acquisition, or asset
                                        transfer, your personal data may
                                        <br />
                                        be subject to transfer. Prior notice will be provided before the transfer
                                        of personal data, at which point
                                        <br />
                                        it will become subject to an alternative Privacy Policy.
                                        <br />
                                        Under specific circumstances, CureBay may be compelled to disclose an
                                        individual’s personal data as
                                        <br />
                                        mandated by law or in response to valid requests from public authorities,
                                        such as a court or
                                        <br />
                                        government agency.
                                        <br />
                                        In certain instances, to facilitate the provision of services, CureBay may
                                        receive information
                                        <br />
                                        pertaining to individuals from third-party entities, such as NSDL and
                                        payment gateway providers,
                                        <br />
                                        concerning the validation of documents and repayment status. The
                                        acquisition and sharing of this
                                        <br />
                                        information shall only occur when strictly necessary for service
                                        provision. CureBay does not retain
                                        <br />
                                        data obtained from these third parties. In fulfilment of our outsourcing
                                        obligations to our partners, this
                                        <br />
                                        information is collected and directly transferred to them upon
                                        acquisition.
                                        <br />
                                        Security of Data:
                                        <br />
                                        The security of your data is important to us, but remember that no method
                                        of transmission over the
                                        <br />
                                        Internet or method of electronic storage is 100% secure. While we strive
                                        to use commercially
                                        <br />
                                        acceptable means to protect your Personal Data, we cannot guarantee its
                                        absolute security.
                                    </p>
                                    <h3>
                                        ‍<br />
                                        9. SERVICE PROVIDERS
                                    </h3>
                                    <p>
                                        ‍<br />
                                        We may employ third-party companies and individuals to facilitate our App
                                        (“Service Providers”),
                                        <br />
                                        provide the App on our behalf, perform App-related services, or assist us
                                        in analysing how our App is
                                        <br />
                                        used.
                                        <br />
                                        These third parties have access to your Personal Data only to perform
                                        these tasks on our behalf and
                                        <br />
                                        are obligated not to disclose or use it for any other purpose.
                                    </p>
                                    <h3>
                                        ‍<br />
                                        10. LINKS TO OTHER SITES
                                    </h3>
                                    <p>
                                        ‍<br />
                                        Our App may contain links to other sites that are not operated by us. If
                                        you click on a third-party link,
                                        <br />
                                        you will be directed to that third party &#39;s site. We strongly
                                        advise you to review the Privacy Policy of
                                        <br />
                                        every site you visit.
                                    </p>
                                    <p>
                                        We have no control over and assume no responsibility for the content,
                                        privacy policies, or practices of
                                        <br />
                                        any third-party sites or services.
                                        <br />
                                        Children’s Privacy:
                                        <br />
                                        Our Website and App does not address anyone under the age of 18
                                        (“Children”).
                                        <br />
                                        We do not knowingly collect personally identifiable information from
                                        anyone under the age of 18. If
                                        <br />
                                        you are a parent or guardian and you are aware that your Children have
                                        provided us with Personal
                                        <br />
                                        Data, please contact us. If we become aware that we have collected
                                        Personal Data from children
                                        <br />
                                        without verification of parental consent, we take steps to remove that
                                        information from our servers.
                                    </p>
                                    <h3>
                                        ‍<br />
                                        11. CHANGES TO THIS PRIVACY POLICY
                                    </h3>
                                    <p>
                                        ‍<br />
                                        We may update our Privacy Policy from time to time. We will notify you of
                                        any changes by posting
                                        <br />
                                        the new Privacy Policy on this page.
                                        <br />
                                        We will let you know via email and/or a prominent notice on our App, prior
                                        to the change becoming
                                        <br />
                                        effective and update the “effective date” at the top of this Privacy
                                        Policy.
                                        <br />
                                        You are advised to review this Privacy Policy periodically for any
                                        changes. Changes to this Privacy
                                        <br />
                                        Policy are effective when they are posted on this page.
                                        <br />
                                        12. GRIEVANCE REDRESSAL
                                        <br />
                                        In accordance with the Information Technology Act, 2000 and rules made
                                        thereunder, CureBay has
                                        <br />
                                        designated a grievance officer (“Grievance Officer”). Any requests,
                                        questions, clarifications or
                                        <br />
                                        grievances with respect to this Privacy Policy or about your Personal Data
                                        can be sent to our
                                        <br />
                                        Grievance Officer at:
                                    </p>
                                    <p>
                                        Name: SHOBHAN MAHAPATRA, Grievance Officer
                                        <br />
                                        Postal address: CureBay Technologies Pvt. Ltd. , Fourth Floor, OCAC Tower, Acharya Vihar,
                                        Bhubaneswar, Odisha - 751013
                                        <br />
                                        Email address:&nbsp;admin@curebay.com
                                        <br />
                                        Helpline number: +91 8335000999
                                        <br />
                                        If you wish to receive a response by email or mail, please be sure to
                                        include your name and postal
                                        <br />
                                        address or email address, as the case may be.
                                        <br />
                                        All grievances shall be redressed in an expeditious manner. Subject to
                                        complete details in relation to
                                        <br />
                                        the grievance being provided, the Grievance Officer shall redress all
                                        grievances within a maximum
                                        <br />
                                        period of 30 days from the date of receipt of the grievance.
                                        <br />
                                        Contact Us:
                                        <br />
                                        If you have any questions about this Privacy Policy, please contact us:
                                        <br />
                                        By email: admin@curebay.com
                                        <br />
                                        By visiting this page on our website: www.curebaytotalcare.com
                                        <br />
                                        By mail: CureBay Technologies Pvt. Ltd. , Fourth Floor, OCAC Tower, Acharya Vihar, Bhubaneswar,
                                        Odisha - 751013
                                    </p>
                                    <p>‍</p>
                                </div>
                            </div>

                        </div>
                    </div>
                </section>
            </div>

            {screen > 500 ? (
                <Footer />
            ) : (
                <>
                    <FooterMobileVersion />
                    <MobileFooter />
                </>
            )}

        </>
    );
};

export default Privacypolicy;
